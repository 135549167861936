/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// import mapboxgl from "mapbox-gl";
import { Link, useNavigate } from "react-router-dom";
import PassengerAccordionComponent from "./PassengerRouteAccordionComponent";
import { createRoute } from "../../../actions/routeActions";
import ArrangeOrderModalV2 from "./ArrangeOrderModalV2";
import { notifyFailure } from "../../../components/toast";
import Select from "react-select";
import AdminRouteUploadFileModal from "./AdminRouteUploadFileModal";

// import AdminBookingReturnTrip from './AdminBookingReturnTrip'

const pickObj = {
  pickUp: "",
  pickUpTime: "",
  dropOff: "",
  dropOffTime: "",
};

const dropObj = {
  pickUp: "",
  pickUpTime: "",
  dropOff: "",
  dropOffTime: "",
};

const customerDetailsObj = {
  customerId: "",
  type: "",
  // capacity: "1",p
  // pick: {
  //   pickUp: "",
  //   pickUpTime: "",
  //   dropOff: "",
  //   dropOffTime: "",
  // },
  daysOfWeek: [
    { day: 1, dayName: "Monday", active: true },
    { day: 2, dayName: "Tuesday", active: true },
    { day: 3, dayName: "Wednesday", active: true },
    { day: 4, dayName: "Thursday", active: true },
    { day: 5, dayName: "Friday", active: true },
    { day: 6, dayName: "Saturday", active: false },
    { day: 7, dayName: "Sunday", active: false },
  ],
  // returnRoute: false,
  shiftTime: "AM",
};

const AdminAddRouteForm = ({ current, i }) => {
  const directionsService = new window.google.maps.DirectionsService();

  // mapboxgl.accessToken =
  //   "pk.eyJ1IjoiaWNvbmljdGVrIiwiYSI6ImNsZW9oZXlsOTAyZmQzcGw4ejA2Zzk2bmYifQ.StmuWRfUB_3kU8yI5fISxQ";
  const auth = useSelector((state) => state.auth);
  // const reservation = useSelector((state) => state.reservation);
  // const kpi = useSelector((state) => state.kpi);
  const location = useSelector((state) => state.location);
  const fare = useSelector((state) => state.fare);
  const students = useSelector((state) => state.students);
  const availableDrivers = useSelector(
    (state) => state.users.allDriversAvailableForRoutes
  );
  const { loading } = students;
  const sockets = useSelector((state) => state.sockets);
  const { socketInstance } = sockets;
  let navigate = useNavigate();

  // const [showDropDown, setshowDropDown] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const toggleUploadFileModal = () => {
    setShowUploadFileModal((showDropDown) => !showDropDown);
  };
  const [isRidePatternArranged, setIsRidePatternArranged] = useState(false);
  const [orignalRidePattern, setOrignalRidePattern] = useState([]);
  const [orignalRidePatternWithDistance, setOrignalRidePatternWithDistance] =
    useState([]);

  const [returnRidePattern, setReturnRidePattern] = useState([]);
  const [returnRidePatternWithDistance, setReturnRidePatternWithDistance] =
    useState([]);

  const [customerDetailsArr, setCustomerDetailsArr] = useState([
    customerDetailsObj,
  ]);

  // console.log(customerDetailsArr, "customerDetailsArr==>");

  const [calculationFinished, setCalculationFinished] = useState(false);
  const [submitFlag, setSubmitFlag] = useState(false);

  // const [showPickLoc, setShowPicLoc] = useState(false);
  // const openPickLoc = () => {
  //   setShowPicLoc((showPickLoc) => !showPickLoc);
  // };

  const [values, setValues] = useState({ shiftTime: "" });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const dispatch = useDispatch();

  // console.log("patt ==>", orignalRidePattern, orignalRidePatternWithDistance);
  const handleSubmit = async (event) => {
    event.preventDefault();
    // beforeSubmit();

    // if (!isRidePatternArranged) {
    //   notifyFailure("Please arrange ride pattern first !");
    //   setShowModal(true);
    //   return;
    // }

    // if (!calculationFinished) {
    //   notifyFailure("Please wait until ride pattern calculation is finished!");
    //   setSubmitFlag(true);
    //   return;
    // }

    // tempReturnRouteArr.reverse();

    submitRoute();
  };

  const submitRoute = () => {
    setSubmitFlag(false);
    const chicagoDateOptions = {
      timeZone: "America/Chicago",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    const chicagoDate = new Date().toLocaleDateString(
      "en-US",
      chicagoDateOptions
    );
    const parts = chicagoDate.split("/");
    const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
    const customerDetailsAM = customerDetailsArr
      .filter((x) => x.shiftTime === "AM")
      .map((x) => ({
        customerId: x.customerId,
        pickUpAddress: x.pickUpAddress,
        pickUpTime: x.pickUpTime,
        dropOffAddress: x.dropOffAddress,
        dropOffTime: x.dropOffTime,
        type: x.type,
        daysOfWeek: x.daysOfWeek,
        fareAndDistance: x.fareAndDistance,
        dispatchNotes: x.dispatchNotes,
        schoolName: x.schoolName,
        shiftTime: x.shiftTime,
        fareAndDistance: x.fareAndDistance,
        dateRange: [{ start: formattedChicagoDate }],
      }));
    for (var i = 0; i < customerDetailsArr.length; i++) {
      if (customerDetailsArr[i].shiftAM) {
        customerDetailsAM.push({
          // customerId: customerDetailsAM[i].customerId,
          // pickUpAddress: customerDetailsAM[i].pickUpAddress,
          // Add other properties as needed
          customerId: customerDetailsArr[i].customerId,
          pickUpAddress: customerDetailsArr[i].pickUpAddressAM,
          pickUpTime: customerDetailsArr[i].pickUpTimeAM,
          dropOffAddress: customerDetailsArr[i].dropOffAddressAM,
          dropOffTime: customerDetailsArr[i].dropOffTimeAM,
          type: customerDetailsArr[i].type,
          daysOfWeek: customerDetailsArr[i].daysOfWeek,
          fareAndDistance: customerDetailsArr[i].fareAndDistance,
          dispatchNotes: customerDetailsArr[i].dispatchNotes,
          schoolName: customerDetailsArr[i].schoolName,
          shiftTime: customerDetailsArr[i].shiftTime,
          fareAndDistance: customerDetailsArr[i].fareAndDistanceAM,
          dateRange: [{ start: formattedChicagoDate }],
        });
      }
    }

    const customerDetailsPM = customerDetailsArr
      .filter((x) => x.shiftTime === "PM")
      .map((x) => ({
        customerId: x.customerId,
        pickUpAddress: x.pickUpAddress,
        pickUpTime: x.pickUpTime,
        dropOffAddress: x.dropOffAddress,
        dropOffTime: x.dropOffTime,
        type: x.type,
        daysOfWeek: x.daysOfWeek,
        fareAndDistance: x.fareAndDistance,
        dispatchNotes: x.dispatchNotes,
        schoolName: x.schoolName,
        fareAndDistance: x.fareAndDistance,
        dateRange: [{ start: formattedChicagoDate }],
      }));

    for (var i = 0; i < customerDetailsArr.length; i++) {
      if (customerDetailsArr[i].shiftPM) {
        customerDetailsPM.push({
          // customerId: customerDetailsAM[i].customerId,
          // pickUpAddress: customerDetailsAM[i].pickUpAddress,
          // Add other properties as needed
          customerId: customerDetailsArr[i].customerId,
          pickUpAddress: customerDetailsArr[i].pickUpAddressPM,
          pickUpTime: customerDetailsArr[i].pickUpTimePM,
          dropOffAddress: customerDetailsArr[i].dropOffAddressPM,
          dropOffTime: customerDetailsArr[i].dropOffTimePM,
          type: customerDetailsArr[i].type,
          daysOfWeek: customerDetailsArr[i].daysOfWeek,
          fareAndDistance: customerDetailsArr[i].fareAndDistance,
          dispatchNotes: customerDetailsArr[i].dispatchNotes,
          schoolName: customerDetailsArr[i].schoolName,
          shiftTime: customerDetailsArr[i].shiftTime,
          fareAndDistance: customerDetailsArr[i].fareAndDistancePM,
          dateRange: [{ start: formattedChicagoDate }],
        });
      }
    }

    const customerDetailsMID = customerDetailsArr
      .filter((x) => x.shiftTime === "MID")
      .map((x) => ({
        customerId: x.customerId,
        pickUpAddress: x.pickUpAddress,
        pickUpTime: x.pickUpTime,
        dropOffAddress: x.dropOffAddress,
        dropOffTime: x.dropOffTime,
        type: x.type,
        daysOfWeek: x.daysOfWeek,
        fareAndDistance: x.fareAndDistance,
        dispatchNotes: x.dispatchNotes,
        schoolName: x.schoolName,
        fareAndDistance: x.fareAndDistance,
        dateRange: [{ start: formattedChicagoDate }],
      }));
    for (var i = 0; i < customerDetailsArr.length; i++) {
      if (customerDetailsArr[i].shiftMID) {
        customerDetailsMID.push({
          // customerId: customerDetailsAM[i].customerId,
          // pickUpAddress: customerDetailsAM[i].pickUpAddress,
          // Add other properties as needed
          customerId: customerDetailsArr[i].customerId,
          pickUpAddress: customerDetailsArr[i].pickUpAddressMID,
          pickUpTime: customerDetailsArr[i].pickUpTimeMID,
          dropOffAddress: customerDetailsArr[i].dropOffAddressMID,
          dropOffTime: customerDetailsArr[i].dropOffTimeMID,
          type: customerDetailsArr[i].type,
          daysOfWeek: customerDetailsArr[i].daysOfWeek,
          fareAndDistance: customerDetailsArr[i].fareAndDistance,
          dispatchNotes: customerDetailsArr[i].dispatchNotes,
          schoolName: customerDetailsArr[i].schoolName,
          shiftTime: customerDetailsArr[i].shiftTime,
          fareAndDistance: customerDetailsArr[i].fareAndDistanceMID,
          dateRange: [{ start: formattedChicagoDate }],
        });
      }
    }
    // console.log(customerDetailsAM, "customerDetailsArr");
    // console.log(customerDetailsAM, "customerDetailsAM");

    // console.log(customerDetailsPM, "customerDetailsPM");
    // console.log(customerDetailsMID, "customerDetailsMID");

    let tempOrignalRouteArr = [];

    // if (orignalRidePattern.length === 0) {
    //   for (let i = 0; i < customerDetails.length; i++) {
    //     tempOrignalRouteArr.push({
    //       customerId: customerDetailsArr[i].customerId,
    //       address: customerDetailsArr[i].pickUpAddress,
    //       time: customerDetailsArr[i].pickUpTime,
    //       routeType: "Pick",
    //       customerDetails: customerDetailsArr[i],
    //     });
    //     tempOrignalRouteArr.push({
    //       customerId: customerDetailsArr[i].customerId,
    //       address: customerDetailsArr[i].dropOffAddress,
    //       time: customerDetailsArr[i].dropOffTime,
    //       routeType: "Drop",
    //       customerDetails: customerDetailsArr[i],
    //     });
    //   }

    //   calculateOrignalMileageAndTime(tempOrignalRouteArr);
    // }
    dispatch(
      createRoute(
        {
          companyId: auth.user.companyId.id,
          routeNumber: values.routeNumber,
          driverId: values.driverId,
          shiftTime: "AM",
          // routeNumberReturn: `return-${values.routeNumber}`,
          // customerDetails: passengerRouteWithDistanceArr,
          customerDetailsAM,
          customerDetailsPM,
          customerDetailsMID,
          // ridePattern:
          //   orignalRidePattern.length > 0
          //     ? orignalRidePatternWithDistance
          //     : tempOrignalRouteArr,
          // ridePattern: orignalRidePatternWithDistance,
          bookingDate: new Date().toISOString(),
          // totalAmount: customerDetailsArr.reduce(function (
          //   previousValue,
          //   currentValue
          // ) {
          //   return previousValue + currentValue?.fareAndDistance?.rideFare;
          // },
          // 0),
          // isReturnRoute: customerDetailsArr.find((z) => z.returnRoute)
          //   ? true
          //   : false,
          // totalMileage: customerDetailsArr.reduce(function (
          //   previousValue,
          //   currentValue
          // ) {
          //   return previousValue + currentValue?.fareAndDistance?.totalMileage;
          // },
          // 0),
          // totalDrivenMileage: orignalRidePatternWithDistance.reduce(function (
          //   previousValue,
          //   currentValue
          // ) {
          //   return previousValue + currentValue?.distanceFromLastPoint;
          // },
          // 0),
        },
        // orignalRouteSuccessHandler
        successHandler
      )
    );
  };
  useEffect(() => {
    if (calculationFinished && submitFlag) submitRoute();
  }, [calculationFinished, submitFlag]);

  const orignalRouteSuccessHandler = () => {
    const returnRouteCustomerDetails = customerDetailsArr
      .filter((x) => x.returnRoute)
      .map((x) => ({
        // ...x,
        customerId: x.customerId,
        pickUpAddress: x.returnPickUpAddress,
        pickUpTime: x.returnPickUpTime,
        dropOffAddress: x.returnDropOffAddress,
        dropOffTime: x.returnDropOffTime,
        type: x.type,
        // capacity: x.capacity,
        daysOfWeek: x.daysOfWeek,
        fareAndDistance: x.returnFareAndDistance,
        // fareAndDistance: x.returnFareAndDistance,
        dispatchNotes: x.dispatchNotes,
        schoolName: x.schoolName,
        // fareAndDistance: x.fareAndDistance,
      }));

    // let tempReturnRouteArr = [];
    // if (returnRidePattern.length === 0) {
    //   for (let i = 0; i < returnRouteCustomerDetails.length; i++) {
    //     tempReturnRouteArr.push({
    //       customerId: customerDetailsArr[i].customerId,
    //       address: customerDetailsArr[i].dropOffAddress,
    //       time: customerDetailsArr[i].dropOffTime,
    //       customerDetails: customerDetailsArr[i],

    //       routeType: "Drop",
    //     });
    //     tempReturnRouteArr.push({
    //       customerId: customerDetailsArr[i].customerId,
    //       address: customerDetailsArr[i].pickUpAddress,
    //       time: customerDetailsArr[i].pickUpTime,
    //       customerDetails: customerDetailsArr[i],

    //       routeType: "Pick",
    //     });
    //   }
    //   tempReturnRouteArr.reverse();
    //   calculateReturnMileageAndTime(tempReturnRouteArr);
    // }

    if (
      customerDetailsArr.filter((x) => x.returnRoute).length > 0 &&
      returnRidePattern.length > 0
    ) {
      dispatch(
        createRoute(
          {
            companyId: auth.user.companyId.id,
            routeNumber: values.routeNumber,
            driverId: values.driverId,
            shiftTime:
              values.shiftTime === "Morning | AM"
                ? "Afternoon | PM"
                : "Morning | AM",
            routeNumberReturn: `return-${values.routeNumber}`,
            // customerDetails: passengerRouteWithDistanceArr,
            customerDetails: returnRouteCustomerDetails,

            // ridePattern:
            // returnRidePattern.length > 0
            //   ? returnRidePattern
            //   : tempReturnRouteArr,

            ridePattern: returnRidePatternWithDistance,

            bookingDate: new Date().toISOString(),
            // totalAmount: returnRouteCustomerDetails.reduce(function (
            //   previousValue,
            //   currentValue
            // ) {
            //   return previousValue + currentValue?.fareAndDistance?.rideFare;
            // },
            // 0),

            totalMileage: returnRouteCustomerDetails.reduce(function (
              previousValue,
              currentValue
            ) {
              return (
                previousValue + currentValue?.fareAndDistance?.totalMileage
              );
            },
            0),
            totalDrivenMileage: returnRidePatternWithDistance.reduce(function (
              previousValue,
              currentValue
            ) {
              return previousValue + currentValue?.distanceFromLastPoint;
            },
            0),
          },
          successHandler
        )
      );
    } else {
      successHandler();
    }
  };

  const successHandler = (id) => {
    navigate("/routes");
    const chicagoDateOptions = {
      timeZone: "America/Chicago",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    const chicagoDate = new Date().toLocaleDateString(
      "en-US",
      chicagoDateOptions
    );
    const parts = chicagoDate.split("/");
    const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
    socketInstance?.emit("send-reservation-req", {
      userId: auth.user.id,
      companyId: auth.user.companyId.id,
      id: id,
      today: formattedChicagoDate,
      role: "admin",
    });
  };

  // console.log("orignal time==>", orignalRidePatternWithDistance);
  const calculateOrignalMileageAndTime = async (data) => {
    setCalculationFinished(false);

    let drivenMileage = 0;

    // let lastTime = data[0].time;
    let lastTime = customerDetailsArr.find(
      (x) => x?.customerId === data[0].customerId
    )?.pickUpTime;

    // console.log("last==>", lastTime, customerDetailsArr);
    let arr = [
      {
        customerDetails: data[0].customerDetails,
        customerId: data[0].customerId,

        distanceFromLastPoint: 0,
        routeType: data[0].routeType,
        address: data[0].address,
        time: lastTime,
        timeFromLastPoint: `0 mins`,
      },
    ];

    let eta = { hour: 0, mins: 0 };

    let estimatedTime;
    for (let i = 1; i < data.length; i++) {
      if (data[i - 1]?.address?.address == data[i]?.address?.address) {
        arr.push({
          customerDetails: data[i].customerDetails,
          customerId: data[i].customerId,

          distanceFromLastPoint: 0,
          routeType: data[i].routeType,
          address: data[i].address,
          time: lastTime,
          timeFromLastPoint: "0 mins",
        });
      } else {
        await directionsService
          .route({
            origin: {
              query: data[i - 1]?.address?.address,
            },
            destination: {
              query: data[i]?.address?.address,
            },
            travelMode: window.google.maps.TravelMode.DRIVING,
          })
          .then((response) => {
            // console.log("response2", response);
            drivenMileage = parseFloat(
              response.routes[0].legs[0].distance.text
                .replace(/,/g, "")
                .split(" mi")[0]
            );

            let oldDate = new Date("2023-04-20T" + lastTime + ":00.000Z");

            // yahan se new logic
            if (response.routes[0].legs[0].duration.text.length > 7) {
              const timeString = response.routes[0].legs[0].duration.text;
              const hourRegex = /(\d+)\s*hour/;
              const minuteRegex = /(\d+)\s*min/;

              const hourMatch = hourRegex.exec(timeString);
              const minuteMatch = minuteRegex.exec(timeString);

              const hours = hourMatch ? parseInt(hourMatch[1]) : 0;
              const minutes = minuteMatch ? parseInt(minuteMatch[1]) : 0;

              eta.hour = hours;
              eta.mins = minutes;

              let aaa = (hours * 60 + minutes) * 60 * 1000;

              estimatedTime = new Date(aaa + new Date(oldDate).getTime())
                .toISOString()
                .slice(11, 16);

              arr.push({
                customerDetails: data[i].customerDetails,
                customerId: data[i].customerId,

                distanceFromLastPoint: drivenMileage,
                timeFromLastPoint: `${aaa / 60000} mins`,
                routeType: data[i].routeType,
                address: data[i].address,
                time: estimatedTime,
              });
              // setCustomerDetailsArr(
              //   customerDetailsArr.map((x, i) =>
              //     i === index
              //       ? {
              //           ...x,
              //           dropOffTime,
              //         }
              //       : x
              //   )
              // );
            } else {
              eta.mins = parseInt(
                response.routes[0].legs[0].duration.text.split(" mins")[0]
              );

              let aaa =
                parseInt(
                  response.routes[0].legs[0].duration.text.split(" mins")[0]
                ) *
                60 *
                1000;

              estimatedTime = new Date(aaa + new Date(oldDate).getTime())
                .toISOString()
                .slice(11, 16);

              arr.push({
                customerDetails: data[i].customerDetails,
                customerId: data[i].customerId,

                distanceFromLastPoint: drivenMileage,
                routeType: data[i].routeType,
                address: data[i].address,
                time: estimatedTime,
                timeFromLastPoint: `${aaa / 60000} mins`,
              });

              // setCustomerDetailsArr(
              //   customerDetailsArr.map((x, i) =>
              //     i === index
              //       ? {
              //           ...x,
              //           dropOffTime: new Date(aaa + new Date(oldDate).getTime())
              //             .toISOString()
              //             .slice(11, 16),
              //         }
              //       : x
              //   )
              // );
            }
          });
        lastTime = estimatedTime;
      }
    }
    // console.log("mileage arr orignal==>", arr, orignalRidePattern);
    setOrignalRidePatternWithDistance(arr);
    setCalculationFinished(true);
  };

  // console.log("calc finished==>", calculationFinished);
  const calculateReturnMileageAndTime = async (data) => {
    setCalculationFinished(false);

    let drivenMileage = 0;

    let lastTime = customerDetailsArr.find(
      (x) => x?.customerId === data[0].customerId
    )?.pickUpTime;

    let arr = [
      {
        customerDetails: data[0].customerDetails,
        customerId: data[0].customerId,
        distanceFromLastPoint: 0,
        routeType: data[0].routeType,
        address: data[0].address,
        time: lastTime,
        timeFromLastPoint: `0 mins`,
      },
    ];

    let eta = { hour: 0, mins: 0 };

    let estimatedTime;

    for (let i = 1; i < data.length; i++) {
      if (data[i - 1].address.address == data[i].address.address) {
        arr.push({
          distanceFromLastPoint: 0,
          routeType: data[i].routeType,
          address: data[i].address,
          time: lastTime,
          timeFromLastPoint: data[i - 1].timeFromLastPoint,
          customerDetails: data[i].customerDetails,
          customerId: data[i].customerId,
        });
        // lastTime = data[i - 1].time;
      } else {
        await directionsService
          .route({
            origin: {
              query: data[i - 1]?.address?.address,
            },
            destination: {
              query: data[i]?.address?.address,
            },
            travelMode: window.google.maps.TravelMode.DRIVING,
          })
          .then((response) => {
            // console.log("response2", response);
            drivenMileage = parseFloat(
              response.routes[0].legs[0].distance.text
                .replace(/,/g, "")
                .split(" mi")[0]
            );

            let oldDate = new Date("2023-04-20T" + lastTime + ":00.000Z");

            // yahan se new logic
            if (response.routes[0].legs[0].duration.text.length > 7) {
              const timeString = response.routes[0].legs[0].duration.text;
              const hourRegex = /(\d+)\s*hour/;
              const minuteRegex = /(\d+)\s*min/;

              const hourMatch = hourRegex.exec(timeString);
              const minuteMatch = minuteRegex.exec(timeString);

              const hours = hourMatch ? parseInt(hourMatch[1]) : 0;
              const minutes = minuteMatch ? parseInt(minuteMatch[1]) : 0;

              eta.hour = hours;
              eta.mins = minutes;

              let aaa = (hours * 60 + minutes) * 60 * 1000;

              estimatedTime = new Date(aaa + new Date(oldDate).getTime())
                .toISOString()
                .slice(11, 16);

              // console.log(
              //   "eta if==>",

              //   estimatedTime,
              //   lastTime,
              //   data[i]
              // );

              arr.push({
                customerDetails: data[i].customerDetails,
                customerId: data[i].customerId,

                distanceFromLastPoint: drivenMileage,
                routeType: data[i].routeType,
                address: data[i].address,
                time: estimatedTime,
                timeFromLastPoint: `${aaa / 60000} mins`,
              });
            } else {
              eta.mins = parseInt(
                response.routes[0].legs[0].duration.text.split(" mins")[0]
              );

              let aaa =
                parseInt(
                  response.routes[0].legs[0].duration.text.split(" mins")[0]
                ) *
                60 *
                1000;

              estimatedTime = new Date(aaa + new Date(oldDate).getTime())
                .toISOString()
                .slice(11, 16);

              // console.log("eta else==>", estimatedTime, lastTime, data[i]);

              arr.push({
                customerDetails: data[i].customerDetails,
                customerId: data[i].customerId,

                distanceFromLastPoint: drivenMileage,
                routeType: data[i].routeType,
                address: data[i].address,
                time: estimatedTime,
                timeFromLastPoint: `${aaa / 60000} mins`,
              });
            }
          });
        lastTime = estimatedTime;
      }
    }
    // console.log("mileage arr return==>", arr);
    setReturnRidePatternWithDistance(arr);
    setCalculationFinished(true);
  };

  useEffect(() => {
    if (orignalRidePattern.length > 0)
      calculateOrignalMileageAndTime(orignalRidePattern);
  }, [
    orignalRidePattern,
    customerDetailsArr.pickUpTime,
    customerDetailsArr.pickUpAddress,
    customerDetailsArr.dropOffAddress,
  ]);

  useEffect(() => {
    if (returnRidePattern.length > 0)
      calculateReturnMileageAndTime(returnRidePattern);
  }, [
    returnRidePattern,
    customerDetailsArr.returnPickUpTime,
    customerDetailsArr.returnPickUpAddress,
    customerDetailsArr.returnDropOffAddress,
  ]);

  // console.log("calc orignal ride pattern ===>", orignalRidePatternWithDistance);

  // console.log(
  //   "return ===>",
  //   returnRidePattern,
  //   customerDetailsArr.filter((x) => x.returnRoute)
  // );

  return (
    <>
      <div className="content-wrapper">
        <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
          <div
            id="leads-table_wrapper"
            className="dataTables_wrapper dt-bootstrap4 no-footer p-3"
          >
            <div className="bg-white  text-capitalize d-flex justify-content-between  border-bottom-grey pb-3 px-2">
              <h4 className="f-18 f-w-500 mb-0">Add Route</h4>
              <div
                className="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3"
                role="group"
              >
                <div
                  className="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3"
                  role="group"
                >
                  <div
                    className="btn-group me-2"
                    role="group"
                    aria-label="First group"
                  >
                    <button
                      type="button"
                      className="btn btn-info"
                      style={{ backgroundColor: "#14737D" }}
                      data-toggle="tooltip"
                      data-placement="top"
                      title=""
                      data-bs-original-title="Upload File"
                      aria-label="Upload File"
                      onClick={() => setShowUploadFileModal(true)}
                    >
                      <i
                        className="fas fa-upload type="
                        button=""
                        data-toggle="modal"
                        data-target="#uploadDoc"
                      ></i>
                    </button>
                    <Link
                      to="/routes"
                      type="button"
                      className="btn btn-primary"
                    >
                      View Routes{" "}
                      <i className="fas fa-eye  d-sm-inline-block"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-xl-8 col-lg-12 col-md-12 e-d-info-notices">
                  <div className="row">
                    <div className="col-sm-4 pl-4">
                      <div className="form-group my-3">
                        <div className="d-flex">
                          <div>
                            <label
                              className="f-14 text-dark mb-12"
                              data-label=""
                              htmlFor="default_task_status"
                            >
                              Route Number
                            </label>
                          </div>
                          <div className="p-1">
                            <sup className="text-danger f-12">*</sup>
                          </div>
                        </div>
                        <div className="dropdown bootstrap-select form-control select-picker">
                          <input
                            type="number"
                            className="form-control height-35 f-14"
                            placeholder="Route Number"
                            value={values.routeNumber}
                            name="routeNumber"
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 pl-4">
                      <div className="form-group my-3">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex">
                            <div>
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="clnumber"
                              >
                                Assign Driver
                              </label>
                            </div>
                            {/* <div className="p-1">
                              <sup className="text-danger f-12">*</sup>
                            </div> */}
                          </div>
                        </div>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={""}
                          value={availableDrivers
                            ?.map((x) => ({
                              label: `${x.firstName} ${x.lastName}`,
                              value: x.id,
                            }))
                            .find(
                              (customer) => customer.value === values?.driverId
                            )}
                          isDisabled={false}
                          // isLoading={searchingClients}
                          isClearable={false}
                          isRtl={false}
                          isSearchable={true}
                          // required={true}
                          name="pickUp"
                          onChange={(e) => {
                            setValues({
                              ...values,
                              driverId: e.value,
                            });
                          }}
                          options={availableDrivers?.map((x) => ({
                            label: `${x.firstName} ${x.lastName} `,
                            value: x.id,
                          }))}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <PassengerAccordionComponent
                values={values}
                setValues={setValues}
                handleChange={handleChange}
                customerDetailsArr={customerDetailsArr}
                setCustomerDetailsArr={setCustomerDetailsArr}
                customerDetailsObj={customerDetailsObj}
                dropObj={dropObj}
              />
              <div className="row">
                <div className="col-12 col-md-6 pl-4">
                  <div className="form-group my-5">
                    {true ? (
                      <button
                        type="button"
                        className="btn-primary rounded f-12 p-2 mr-3 "
                        onClick={() => setShowModal(true)}
                      >
                        Arrange Order
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>{" "}
                <div className="col-12 col-md-6 pl-4">
                  <div className="form-group my-5 text-right">
                    {true ? (
                      <button
                        type="submit"
                        className="btn-primary rounded f-12 p-2 mr-3 "
                        disabled={loading}
                        // onClick={submitRouteHandler}
                      >
                        Submit Route
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>{" "}
            </form>
          </div>
        </div>
      </div>

      {showUploadFileModal && (
        <AdminRouteUploadFileModal
          showDropDown={showUploadFileModal}
          toggleUploadFileModal={toggleUploadFileModal}
        />
      )}

      {showModal && (
        <ArrangeOrderModalV2
          showModal={showModal}
          setShowModal={setShowModal}
          customerDetailsArr={customerDetailsArr}
          setCustomerDetailsArr={setCustomerDetailsArr}
          orignalRidePattern={orignalRidePattern}
          setOrignalRidePattern={setOrignalRidePattern}
          returnRidePattern={returnRidePattern}
          setReturnRidePattern={setReturnRidePattern}
          isRidePatternArranged={isRidePatternArranged}
          setIsRidePatternArranged={setIsRidePatternArranged}
        />
      )}
    </>
  );
};
export default AdminAddRouteForm;
