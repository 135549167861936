/** @format */

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateRouteById } from "../../../actions/routeActions";

const AdminRouteReactivateModal = ({
  showModal,
  setShowModal,
  selectedRoute,
}) => {
  const dispatch = useDispatch();
  const submitHandler = async (event) => {
    event.preventDefault();
    const chicagoDateOptions = {
      timeZone: "America/Chicago",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    const chicagoDate = new Date().toLocaleDateString(
      "en-US",
      chicagoDateOptions
    );
    const parts = chicagoDate.split("/");
    const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;

    dispatch(
      updateRouteById(
        {
          _id: selectedRoute?.id ? selectedRoute?.id : selectedRoute,
          disable: false,
          routeEndDate: formattedChicagoDate,
        },
        successHandler
      )
    );
  };
  const auth = useSelector((state) => state.auth);
  const sockets = useSelector((state) => state.sockets);
  const { socketInstance } = sockets;
  const successHandler = (id) => {
    setShowModal(false);
    const chicagoDateOptions = {
      timeZone: "America/Chicago",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    const chicagoDate = new Date().toLocaleDateString(
      "en-US",
      chicagoDateOptions
    );
    const parts = chicagoDate.split("/");
    const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
    socketInstance?.emit("send-route-req", {
      userId: auth.user.id,
      companyId: auth.user.companyId.id,
      id: id,
      today: formattedChicagoDate,
      role: "admin",
    });
  };

  return (
    <>
      <div
        className={`modal fade ${showModal ? "show modal-y-axis" : "hide"}`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false"
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            setShowModal(false);
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <form onSubmit={submitHandler}>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  {" "}
                  Reactivate Route
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShowModal(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={{ width: "50% !important" }}>
                <div className="row">
                  <div className="col-md-12">
                    <p>Are you sure you want to Reactivate? </p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn-secondary rounded f-14 p-2 mr-3"
                  data-dismiss="modal"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn-primary rounded f-14 p-2 mr-3"
                >
                  Yes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminRouteReactivateModal;
