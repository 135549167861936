/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePassenger,
  // getPassengersByCompanyIdWithPagination,
} from "../../../actions/userActions";
import {
  getExternalCompanies,
  updateExternalCompany,
} from "../../../actions/companyAction";
import { allFacilities } from "../../../actions/kpiActions";
// import {
//   deleteClientorUser,
//   getAllUser,
//   getAllUsers,
// } from "../../../actions/userActions";

const AdminFacilityDelete = ({
  showDeleteDropDown,
  openDeleteDropDown,
  current,
  index,
}) => {
  const [values, setValues] = useState({
    _id: "",
    delete: "",
  });
  // console.log(current, "currentcurrent");
  const auth = useSelector((state) => state.auth);
  const users = useSelector((state) => state.users);
  const students = useSelector((state) => state.students);
  const { loading } = students;

  useEffect(() => {
    setValues({ ...values, _id: current?.id });
  }, [current]);
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const data = {
    delete: true,
    _id: values._id,
  };
  const dispatch = useDispatch();
  const handleSumbit = async (event) => {
    event.preventDefault();
    dispatch(updateExternalCompany(data, successHandler));
    openDeleteDropDown();
  };
  const successHandler = () => {
    dispatch(getExternalCompanies({ companyId: auth.user.companyId.id }));
    dispatch(allFacilities({ companyId: auth.user.companyId.id }));
  };
  return (
    <>
      <div
        className={`modal fade ${
          showDeleteDropDown === true ? "show modal-y-axis" : "hide"
        }`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDeleteDropDown(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <form onSubmit={handleSumbit}>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  {" "}
                  Delete Facility
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => openDeleteDropDown()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={{ width: "50% !important" }}>
                <div className="row">
                  <div className="col-md-12">
                    <p>Are you sure you want to delele? </p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn-secondary rounded f-14 p-2 mr-3"
                  data-dismiss="modal"
                  onClick={() => openDeleteDropDown()}
                  disabled={loading}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn-primary rounded f-14 p-2 mr-3"
                  disabled={loading}
                >
                  Yes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminFacilityDelete;
