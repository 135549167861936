import axios from "axios";
import { notifyFailure, notifySuccess } from "../components/toast";
import {
  // ADMIN_COMPANY_INFO_VIEW_REQUEST,
  // ADMIN_COMPANY_INFO_VIEW_SUCCESS,
  // ADMIN_COMPANY_INFO_VIEW_FAIL,
  // ADMIN_COMPANY_INFO_CREATE_REQUEST,
  // ADMIN_COMPANY_INFO_CREATE_SUCCESS,
  // ADMIN_COMPANY_INFO_CREATE_FAIL,
  SERVERADDRESS,
  COMPANY_CREATE_REQUEST,
  COMPANY_CREATE_SUCCESS,
  COMPANY_CREATE_FAIL,
  EXTERNAL_COMPANIES_UPDATE_REQUEST,
  EXTERNAL_COMPANIES_UPDATE_SUCCESS,
  EXTERNAL_COMPANIES_UPDATE_FAIL,
  COMPANY_AUTH_FAIL,
  EXTERNAL_COMPANIES_GET_REQUEST,
  EXTERNAL_COMPANIES_GET_SUCCESS,
  EXTERNAL_COMPANIES_GET_FAIL,
  // COMPANY_UPDATE_REQUEST,
  // COMPANY_UPDATE_SUCCESS,
  // COMPANY_UPDATE_FAIL,
} from "../constants";

// ALL ADMIN COMPANY INFO DISPLAY VIEW
// export const getAdminCompanyInfo = (obj) => async (dispatch) => {
//   dispatch({
//     type: ADMIN_COMPANY_INFO_VIEW_REQUEST,
//   });
//   try {
//     const { data } = await axios.patch(
//       `${SERVERADDRESS}/v1/users/userById`,
//       obj
//     );
//     localStorage.setItem("adminCompanyInfo", JSON.stringify(data));
//     dispatch({
//       type: ADMIN_COMPANY_INFO_VIEW_SUCCESS,
//       payload: data,
//     });
//   } catch (err) {
//     console.log(err);
//     dispatch({
//       type: ADMIN_COMPANY_INFO_VIEW_FAIL,
//       payload: err,
//     });
//   }
// };

// ADD NEW ADMIN COMPANY INFO
// export const addCompany = (obj) => async ( dispatch ) => {
//     dispatch({
//         type: ADMIN_COMPANY_INFO_CREATE_REQUEST,
//     })
//     try {
//         const { data } = await axios.post(`${SERVERADDRESS}`, obj)
//     dispatch({
//         type: ADMIN_COMPANY_INFO_CREATE_SUCCESS,
//         payload: data,
//     })
// }
// catch (err) {
//     console.log(err)
//     dispatch({
//         type: ADMIN_COMPANY_INFO_CREATE_FAIL,
//         payload: err,
//     })
// }
// }

export const getExternalCompanies =
  (obj, successHandler) => async (dispatch) => {
    dispatch({
      type: EXTERNAL_COMPANIES_GET_REQUEST,
    });
    try {
      const { data } = await axios.post(
        `${SERVERADDRESS}/v1/externalCompany/getExternalCompanysByCompanyId`,
        obj
      );
      dispatch({
        type: EXTERNAL_COMPANIES_GET_SUCCESS,
        payload: data,
      });
      // successHandler(data);
    } catch (err) {
      // console.log(err);
      dispatch({
        type: EXTERNAL_COMPANIES_GET_FAIL,
        payload: err,
      });
    }
  };
// ADD NEW External Company
export const createExternalCompany =
  (obj, successHandler) => async (dispatch) => {
    dispatch({
      type: COMPANY_CREATE_REQUEST,
    });
    try {
      const { data } = await axios.post(
        `${SERVERADDRESS}/v1/externalCompany/createExternalCompany`,
        obj
      );
      notifySuccess("Company created successfully !");

      dispatch({
        type: COMPANY_CREATE_SUCCESS,
        payload: data,
      });
      successHandler(data);
    } catch (err) {
      // console.log(err);
      notifyFailure(err.response.data.message);

      dispatch({
        type: COMPANY_CREATE_FAIL,
        payload: err,
      });
    }
  };

// ADD NEW External Company
export const updateExternalCompany =
  (obj, successHandler) => async (dispatch) => {
    dispatch({
      type: EXTERNAL_COMPANIES_UPDATE_REQUEST,
    });
    try {
      const { data } = await axios.put(
        `${SERVERADDRESS}/v1/externalCompany/updateExternalCompany`,
        obj
      );
      notifySuccess("Company created successfully !");

      dispatch({
        type: EXTERNAL_COMPANIES_UPDATE_SUCCESS,
        payload: data,
      });
      successHandler(data);
    } catch (err) {
      // console.log(err);
      notifyFailure(err.response.data.message);

      dispatch({
        type: EXTERNAL_COMPANIES_UPDATE_FAIL,
        payload: err,
      });
    }
  };

// export const updateCompany = (obj, handleSuccess) => async (dispatch) => {
//   dispatch({
//     type: COMPANY_UPDATE_REQUEST,
//   });
//   try {
//     const { data } = await axios.put(
//       `${SERVERADDRESS}/v1/company/updateCompany`,
//       obj
//     );
//     notifySuccess("Company updated successfully !");

//     handleSuccess();
//     dispatch({
//       type: COMPANY_UPDATE_SUCCESS,
//       payload: data,
//     });
//   } catch (err) {
//     console.log(err);
//     notifyFailure(err?.response?.data?.message);

//     dispatch({
//       type: COMPANY_UPDATE_FAIL,
//       payload: err,
//     });
//   }
// };
