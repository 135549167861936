/** @format */

import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  getAllRoutesOfCompanyV2,
  getRouteandTripByIdV2,
} from "../../../actions/routeActions";
import RoutesTableAccordion from "./RoutesTableAccordion";
import { Link, NavLink } from "react-router-dom";
import ArrangeOrderModalV2 from "./ArrangeOrderModalV2";
import LoadingComponent from "../../../components/LoadingComponent";
import shift1 from "../../../assets/images/load.png";
import AdminRouteDeleteModal from "./AdminRouteDeleteModal";
import { paginationDataToShowRoute } from "../../../actions/generalActions";
import { Accordion } from "react-bootstrap";
import RouteViewModal from "./RouteViewModal";
import moment from "moment";
import { ENVIRONMENT } from "../../../constants";
import { allDriversAvailableForRoutes } from "../../../actions/userActions";
import {
  tabActiveRoutes,
  tabNonActiveRoutes,
} from "../../../actions/tabActions";
// import ArrangeOrderModal from "./ArrangeOrderModal";
// import moment from "moment";

const RoutesTable = ({ routeNo }) => {
  const auth = useSelector((state) => state.auth);
  const routes = useSelector((state) => state.routes);
  const myState = useSelector((state) => state.changeTheTab);

  const { loading, results, error } = routes;

  const [showModal, setShowModal] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRoutePassengersArr, setSelectedRoutePassengersArr] = useState(
    []
  );

  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);

  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };

  const dispatch = useDispatch();

  // newly commented
  // useEffect(() => {
  //   let timerOut = setTimeout(() => {
  //     if (routeNo.length >= 1) {
  //       dispatch(
  //         getAllRoutesOfCompanyV2({
  //           companyId: auth.user.companyId.id,
  //           routeNumber: routeNo,
  //         })
  //       );
  //     } else {
  //       dispatch(
  //         getAllRoutesOfCompanyV2({
  //           companyId: auth.user.companyId.id,
  //         })
  //       );
  //     }
  //   }, 1000);
  //   return () => clearTimeout(timerOut);
  // }, [routeNo]);

  const editOrderHandler = (routeData) => {
    setSelectedRoute(routeData);
    setSelectedRoutePassengersArr(routeData.customerDetails);
    setShowModal(true);
  };

  const deleteRouteHandler = (routeData) => {
    setSelectedRoute(routeData);
    setShowDeleteModal(true);
  };

  useEffect(() => {
    dispatch(
      getAllRoutesOfCompanyV2({
        companyId: auth.user.companyId.id,
      })
    );
    dispatch(
      allDriversAvailableForRoutes({ companyId: auth.user.companyId.id })
    );
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState(
    ENVIRONMENT === "local" ? "id" : "routeNumber"
  );
  const [sortOrder, setSortOrder] = useState(
    ENVIRONMENT === "local" ? "desc" : "asc"
  ); //asc, desc
  const [searchTerm, setSearchTerm] = useState("");
  const [limit, setLimit] = useState(10); // Set your default limit here

  // Function to handle pagination, sorting, and searching
  const handlePagination = (page, field, order, search, newLimit) => {
    let newPage;
    if (page < 1) {
      newPage = 1;
    } else if (page > paginatedData.totalPages) {
      newPage = paginatedData.totalPages;
    } else {
      newPage = page;
    }
    setCurrentPage(newPage);
    setSortField(field);
    setSortOrder(order);
    setSearchTerm(search);
    setLimit(newLimit);
  };

  const filteredData = routes?.routes
    ?.filter((x) => !x?.delete)
    ?.map((current, i) => {
      // Log current to see its value (for debugging purposes)
      // console.log(current, current);
      return {
        current,
        index: i,
      };
    });

  // const fullData = routes?.routes;
  const fullData =
    routes?.routes?.filter((route) => route.disable !== true) || [];
  const paginatedData = paginationDataToShowRoute(
    limit,
    currentPage,
    fullData,
    sortField,
    sortOrder,
    searchTerm
  );
  const fullDataa =
    routes?.routes?.filter((route) => route.disable === true) || [];
  const paginatedData2 = paginationDataToShowRoute(
    limit,
    currentPage,
    fullDataa,
    sortField,
    sortOrder,
    searchTerm
  );

  // Function to handle sorting icon click
  const handleSortClick = (field, order) => {
    // Call handlePagination with sorting parameters
    handlePagination(currentPage, field, order, searchTerm, limit);
  };
  // Function to determine the color based on the current sorting order
  const getArrowColor = (field, direction) => {
    return sortField === field && sortOrder === direction
      ? "#000000"
      : "#A0A0A0";
  };
  // console.log(paginatedData, "paginatedData1");

  const [showRouteViewModal, setShowRouteViewModal] = useState(false);

  const viewRouteHandler = (data) => {
    setSelectedRoute(data);
    setShowRouteViewModal(true);
  };

  // const [selectedRoute, setSelectedRoute] = useState(null);
  const selectRouteHandler = (routeId) => {
    if (selectedRoute === routeId) {
      setSelectedRoute(null);
    } else {
      setSelectedRoute(routeId);
    }
  };
  const prevSelectedRouteRef = useRef();
  useEffect(() => {
    if (selectedRoute) {
      if (prevSelectedRouteRef.current?.id != selectedRoute?.id) {
        prevSelectedRouteRef.current = selectedRoute;
        dispatch(
          getRouteandTripByIdV2({
            _id: selectedRoute.id,
            today: moment().format("YYYY-MM-DD"),
          })
        );
      }
    }
  }, [selectedRoute]);

  // console.log("selected option==>", selectedRoute);
  return (
    <>
      {" "}
      {loading && results?.length == 0 && <LoadingComponent />}
      {myState.viewActiveRoute === "activeRoute" ? (
        <>
          <div className="row align-items-center">
            <div className="col">
              <div className="col-7 mitbsnav">
                <ul className="nav nav-pills nav-justified">
                  <li className="nav-item active">
                    <NavLink
                      to="/routes"
                      onClick={() => dispatch(tabActiveRoutes())}
                      className=""
                    >
                      Active
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/routes"
                      onClick={() => dispatch(tabNonActiveRoutes())}
                      className=""
                    >
                      Non Active
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
      ) : myState.viewActiveRoute === "nonActiveRoute" ? (
        <>
          <div className="row align-items-center">
            <div className="col">
              <div className="col-7 mitbsnav">
                <ul className="nav nav-pills nav-justified">
                  <li className="nav-item">
                    <NavLink
                      to="/routes"
                      onClick={() => dispatch(tabActiveRoutes())}
                      className=""
                    >
                      Active
                    </NavLink>
                  </li>
                  <li className="nav-item active">
                    <NavLink
                      to="/routes"
                      onClick={() => dispatch(tabNonActiveRoutes())}
                      className=""
                    >
                      Non Active
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {myState.viewActiveRoute === "activeRoute" ? (
        <>
          <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
            <div className="d-flex border">
              <div className="flex-grow-1">
                <div
                  className="dataTables_length d-flex"
                  id="leads-table_length"
                >
                  <div className="p-2">
                    <label>Show entries</label>
                  </div>
                  <label>
                    <select
                      name="leads-table_length"
                      aria-controls="leads-table"
                      className="custom-select custom-select-sm form-control form-control-sm"
                      autoComplete="false"
                      value={limit} // Make sure to set the value attribute to the current limit
                      onChange={(e) =>
                        handlePagination(
                          1,
                          sortField,
                          sortOrder,
                          searchTerm,
                          parseInt(e.target.value)
                        )
                      }
                    >
                      {[10, 25, 50, 100].map((current, i) => {
                        return (
                          <option value={current} key={i}>
                            {current}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                </div>
              </div>
              <div className="task-search task-search-new d-flex  py-1 px-lg-3 px-0 border-right-grey align-items-center">
                <form
                  className="w-100 mr-1 mr-lg-0 mr-md-1 ml-md-1 ml-0 ml-lg-0"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="input-group bg-grey rounded">
                    <div className="input-group-prepend">
                      <span className="input-group-text border-0 bg-additional-grey">
                        <i className="fa fa-search f-13 text-dark-grey"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control f-14 p-1 border-additional-grey"
                      id="search-text-field"
                      placeholder="Start typing to search"
                      value={searchTerm}
                      onChange={(e) =>
                        handlePagination(
                          1,
                          sortField,
                          sortOrder,
                          e.target.value,
                          limit
                        )
                      }
                    />
                  </div>
                </form>
              </div>
            </div>
            <div
              id="leads-table_wrapper"
              className="dataTables_wrapper dt-bootstrap4 no-footer"
            >
              <div className="row">
                <div className="col-sm-12">
                  <Accordion className="p-2">
                    {routes &&
                      routes.routes &&
                      routes?.routes?.length > 0 &&
                      paginatedData?.results
                        ?.filter((x) => !x.delete)
                        ?.map((current, i) => {
                          // console.log(current, current);
                          const isDisabled = current.disable !== true;
                          return isDisabled ? (
                            <RoutesTableAccordion
                              current={current}
                              i={i}
                              key={i}
                              editOrderHandler={editOrderHandler}
                              deleteRouteHandler={deleteRouteHandler}
                              viewRouteHandler={viewRouteHandler}
                              selectRoute={selectRouteHandler}
                            />
                          ) : null;
                        })}
                  </Accordion>
                  {routes &&
                    routes?.routes &&
                    paginatedData?.results?.length === 0 && (
                      <div
                        className="dash-stats-list"
                        style={{ textAlign: "center" }}
                      >
                        <br />
                        <br />
                        <img
                          alt=""
                          src={shift1}
                          style={{ width: "145px", opacity: "0.5" }}
                        />
                        <br />
                        <p style={{ opacity: "0.5" }}>No Data Found</p>
                      </div>
                    )}
                </div>
              </div>
              {routes &&
                routes?.routes &&
                routes?.routes?.length > 0 &&
                paginatedData?.results?.length > 0 && (
                  <div className="d-flex border">
                    {routes.loading === false ? (
                      <div className="flex-grow-1">
                        <div
                          className="dataTables_info"
                          id="leads-table_info"
                          role="status"
                          aria-live="polite"
                        >
                          Showing{" "}
                          {1 +
                            paginatedData.limit *
                              (paginatedData?.currentPageNumber - 1)}{" "}
                          to{" "}
                          {paginatedData.displayingResults +
                            paginatedData.limit *
                              (paginatedData?.currentPageNumber - 1)}{" "}
                          of {paginatedData.totalResults} entries
                        </div>
                      </div>
                    ) : (
                      <div className="flex-grow-1">
                        <div
                          className="dataTables_info"
                          id="leads-table_info"
                          role="status"
                          aria-live="polite"
                        >
                          Loading entries
                        </div>
                      </div>
                    )}

                    <div>
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="leads-table_paginate"
                      >
                        <ul className="pagination">
                          <li className="page-item">
                            <NavLink
                              className="page-link"
                              to="#"
                              aria-label="Previous"
                              onClick={(e) =>
                                handlePagination(
                                  currentPage - 1,
                                  sortField,
                                  sortOrder,
                                  searchTerm,
                                  limit
                                )
                              }
                            >
                              <span aria-hidden="true">Previous</span>
                              <span className="sr-only">Previous</span>
                            </NavLink>
                          </li>
                          {routes &&
                            routes?.routes &&
                            paginatedData?.paginationArr &&
                            paginatedData?.paginationArr?.length > 0 &&
                            Array.from(
                              {
                                length:
                                  paginatedData.endPage -
                                  paginatedData.startPage +
                                  1,
                              },
                              (_, i) => {
                                const current = paginatedData.startPage + i;
                                return paginatedData?.currentPageNumber ===
                                  current ? (
                                  <li className="page-item active" key={i}>
                                    <Link
                                      to=""
                                      className="page-link"
                                      value={current}
                                    >
                                      {current}
                                    </Link>
                                  </li>
                                ) : (
                                  <li className="page-item" key={i}>
                                    <Link
                                      className="page-link"
                                      value={current}
                                      onClick={(e) =>
                                        handlePagination(
                                          parseInt(current),
                                          sortField,
                                          sortOrder,
                                          searchTerm,
                                          limit
                                        )
                                      }
                                      to=""
                                    >
                                      {current}
                                    </Link>
                                  </li>
                                );
                              }
                            )}
                          <li className="page-item">
                            <NavLink
                              className="page-link"
                              to="#"
                              aria-label="Next"
                              onClick={(e) =>
                                handlePagination(
                                  currentPage + 1,
                                  sortField,
                                  sortOrder,
                                  searchTerm,

                                  limit
                                )
                              }
                            >
                              <span aria-hidden="true">Next</span>
                              <span className="sr-only">Next</span>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </>
      ) : myState.viewActiveRoute === "nonActiveRoute" ? (
        <>
          <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
            <div className="d-flex border">
              <div className="flex-grow-1">
                <div
                  className="dataTables_length d-flex"
                  id="leads-table_length"
                >
                  <div className="p-2">
                    <label>Show entries</label>
                  </div>
                  <label>
                    <select
                      name="leads-table_length"
                      aria-controls="leads-table"
                      className="custom-select custom-select-sm form-control form-control-sm"
                      autoComplete="false"
                      value={limit} // Make sure to set the value attribute to the current limit
                      onChange={(e) =>
                        handlePagination(
                          1,
                          sortField,
                          sortOrder,
                          searchTerm,
                          parseInt(e.target.value)
                        )
                      }
                    >
                      {[10, 25, 50, 100].map((current, i) => {
                        return (
                          <option value={current} key={i}>
                            {current}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                </div>
              </div>
              <div className="task-search task-search-new d-flex  py-1 px-lg-3 px-0 border-right-grey align-items-center">
                <form
                  className="w-100 mr-1 mr-lg-0 mr-md-1 ml-md-1 ml-0 ml-lg-0"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="input-group bg-grey rounded">
                    <div className="input-group-prepend">
                      <span className="input-group-text border-0 bg-additional-grey">
                        <i className="fa fa-search f-13 text-dark-grey"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control f-14 p-1 border-additional-grey"
                      id="search-text-field"
                      placeholder="Start typing to search"
                      value={searchTerm}
                      onChange={(e) =>
                        handlePagination(
                          1,
                          sortField,
                          sortOrder,
                          e.target.value,
                          limit
                        )
                      }
                    />
                  </div>
                </form>
              </div>
            </div>
            <div
              id="leads-table_wrapper"
              className="dataTables_wrapper dt-bootstrap4 no-footer"
            >
              <div className="row">
                <div className="col-sm-12">
                  <Accordion className="p-2">
                    {routes &&
                      routes.routes &&
                      routes?.routes?.length > 0 &&
                      paginatedData2?.results
                        ?.filter((x) => !x.delete)
                        ?.map((current, i) => {
                          // console.log(current, current);
                          const isDisabled = current.disable === true;
                          return isDisabled ? (
                            <RoutesTableAccordion
                              current={current}
                              i={i}
                              key={i}
                              editOrderHandler={editOrderHandler}
                              deleteRouteHandler={deleteRouteHandler}
                              viewRouteHandler={viewRouteHandler}
                              selectRoute={selectRouteHandler}
                            />
                          ) : null;
                        })}
                  </Accordion>
                  {routes &&
                    routes?.routes &&
                    paginatedData2?.results?.length === 0 && (
                      <div
                        className="dash-stats-list"
                        style={{ textAlign: "center" }}
                      >
                        <br />
                        <br />
                        <img
                          alt=""
                          src={shift1}
                          style={{ width: "145px", opacity: "0.5" }}
                        />
                        <br />
                        <p style={{ opacity: "0.5" }}>No Data Found</p>
                      </div>
                    )}
                </div>
              </div>
              {routes &&
                routes?.routes &&
                routes?.routes?.length > 0 &&
                paginatedData2?.results?.length > 0 && (
                  <div className="d-flex border">
                    {routes.loading === false ? (
                      <div className="flex-grow-1">
                        <div
                          className="dataTables_info"
                          id="leads-table_info"
                          role="status"
                          aria-live="polite"
                        >
                          Showing{" "}
                          {1 +
                            paginatedData2.limit *
                              (paginatedData2?.currentPageNumber - 1)}{" "}
                          to{" "}
                          {paginatedData2.displayingResults +
                            paginatedData2.limit *
                              (paginatedData2?.currentPageNumber - 1)}{" "}
                          of {paginatedData2.totalResults} entries
                        </div>
                      </div>
                    ) : (
                      <div className="flex-grow-1">
                        <div
                          className="dataTables_info"
                          id="leads-table_info"
                          role="status"
                          aria-live="polite"
                        >
                          Loading entries
                        </div>
                      </div>
                    )}

                    <div>
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="leads-table_paginate"
                      >
                        <ul className="pagination">
                          <li className="page-item">
                            <NavLink
                              className="page-link"
                              to="#"
                              aria-label="Previous"
                              onClick={(e) =>
                                handlePagination(
                                  currentPage - 1,
                                  sortField,
                                  sortOrder,
                                  searchTerm,
                                  limit
                                )
                              }
                            >
                              <span aria-hidden="true">Previous</span>
                              <span className="sr-only">Previous</span>
                            </NavLink>
                          </li>
                          {routes &&
                            routes?.routes &&
                            paginatedData2?.paginationArr &&
                            paginatedData2?.paginationArr?.length > 0 &&
                            Array.from(
                              {
                                length:
                                  paginatedData2.endPage -
                                  paginatedData2.startPage +
                                  1,
                              },
                              (_, i) => {
                                const current = paginatedData2.startPage + i;
                                return paginatedData2?.currentPageNumber ===
                                  current ? (
                                  <li className="page-item active" key={i}>
                                    <Link
                                      to=""
                                      className="page-link"
                                      value={current}
                                    >
                                      {current}
                                    </Link>
                                  </li>
                                ) : (
                                  <li className="page-item" key={i}>
                                    <Link
                                      className="page-link"
                                      value={current}
                                      onClick={(e) =>
                                        handlePagination(
                                          parseInt(current),
                                          sortField,
                                          sortOrder,
                                          searchTerm,
                                          limit
                                        )
                                      }
                                      to=""
                                    >
                                      {current}
                                    </Link>
                                  </li>
                                );
                              }
                            )}
                          <li className="page-item">
                            <NavLink
                              className="page-link"
                              to="#"
                              aria-label="Next"
                              onClick={(e) =>
                                handlePagination(
                                  currentPage + 1,
                                  sortField,
                                  sortOrder,
                                  searchTerm,

                                  limit
                                )
                              }
                            >
                              <span aria-hidden="true">Next</span>
                              <span className="sr-only">Next</span>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <RouteViewModal
        showModal={showRouteViewModal}
        setShowModal={setShowRouteViewModal}
        selectedRoute={selectedRoute}
        setSelectedRoute={setSelectedRoute}
      />
      <ArrangeOrderModalV2
        showModal={showModal}
        setShowModal={setShowModal}
        selectedRoute={selectedRoute}
        setSelectedRoute={setSelectedRoute}
      />
      <AdminRouteDeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        selectedRoute={selectedRoute}
        setSelectedRoute={setSelectedRoute}
      />
    </>
  );
};
export default RoutesTable;
