import React, { useEffect, useState } from "react";
import {
  DirectionsRenderer,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useSelector } from "react-redux";

const containerStyle = {
  width: "100%",
  height: "80vh",
};

const NavigationRoute = () => {
  const routes = useSelector((state) => state.routes);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyC653TecaJSucmBmqXPr9twuP_jG55NACA",
  });

  const directionsService = new window.google.maps.DirectionsService();
  const directionsRenderer = new window.google.maps.DirectionsRenderer();
  // console.log(map)
  const [map, setMap] = React.useState(null);
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);
  // directionsRenderer.setMap(map);
  const [dir, setDir] = useState();
  const [dir2, setDir2] = useState();
  const [dir3, setDir3] = useState([]);
  const [showMap, setShowMap] = useState(true);
  const newArr = routes?.markedLocationRoute?.route?.ridePattern;
  const findDirections = async () => {
    if (routes?.markedLocationRoute?.route?.ridePattern) {
      let arr = [];
      let newArr2 = [];
      // for testing done
      // newArr.forEach((e, i) => {
      //     if (i == 0 ){e.routeType = "Pick"}
      //     if (i == 1 ){e.routeType = "Drop"}
      //     if (i == 2 ){e.routeType = "Pick"}
      //     if (i == 3 ){e.routeType = "Drop"}
      //     if (i >= 2){
      //         e.customerId = "643610cc811b8b1c347dc1cd"
      //     }
      //     else {
      //         e.customerId = "64361091a0bb2e23a82e0e5a"
      //     }
      // });
      // for testing done
      newArr.forEach((e, i) => {
        const customerData =
          routes?.markedLocationRoute?.route?.customerDetails.find(
            (x) => x.customerId.id == e.customerId.id
          );
        newArr2.push({ ...e, ...customerData });
      });
      // console.log(newArr2)
      for (let i = 0; i < newArr2.length - 1; i++) {
        // console.log(newArr2[i].pickUpAddress.latitude,"newArr")
        await directionsService.route(
          {
            origin:
              newArr2[i].routeType === "Pick"
                ? {
                    lat: newArr2[i].pickUpAddress.latitude,
                    lng: newArr2[i].pickUpAddress.longitude,
                  }
                : {
                    lat: newArr2[i].dropOffAddress.latitude,
                    lng: newArr2[i].dropOffAddress.longitude,
                  },
            destination:
              newArr2[i + 1].routeType === "Pick"
                ? {
                    lat: newArr2[i + 1].pickUpAddress.latitude,
                    lng: newArr2[i + 1].pickUpAddress.longitude,
                  }
                : {
                    lat: newArr2[i].dropOffAddress.latitude,
                    lng: newArr2[i].dropOffAddress.longitude,
                  },
            travelMode: window.google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              // this.setState({
              //     directions: result
              // });
              arr.push(result);
              setDir3([...dir3, result]);
              // directionsRenderer.setDirections(result);
              // console.log(result, "resultresultnew")
            } else {
              console.error(`error fetching directions ${result}`);
            }
          }
        );
      }
      // directionsService.route(
      //     {
      //         origin: origin,
      //         destination: destination,
      //         travelMode: window.google.maps.TravelMode.DRIVING
      //     },
      //     (result, status) => {
      //         if (status === window.google.maps.DirectionsStatus.OK) {
      //             // this.setState({
      //             //     directions: result
      //             // });
      //             setDir(result)
      //             directionsRenderer.setDirections(result);
      //             console.log(result, "resultresult")
      //         } else {
      //             console.error(`error fetching directions ${result}`);
      //         }
      //     }
      // );
      // directionsService.route(
      //     {
      //         origin: origin2,
      //         destination: destination2,
      //         travelMode: window.google.maps.TravelMode.DRIVING
      //     },
      //     (result, status) => {
      //         if (status === window.google.maps.DirectionsStatus.OK) {
      //             // this.setState({
      //             //     directions: result
      //             // });
      //             setDir2(result)
      //             // directionsRenderer.setDirections(result);
      //             console.log(result, "resultresult")
      //         } else {
      //             console.error(`error fetching directions ${result}`);
      //         }
      //     }
      // );
      // console.log(arr, dir3, "resultresult")
      setDir3(arr);
      // setShowMap(true)
    }
  };
  useEffect(() => {
    findDirections();
  }, []);
  // console.log(dir3, "resultresult")

  // var positionMarker = {
  //     // path: 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z',
  //     url: 'https://cdn-icons-png.flaticon.com/512/2776/2776067.png',
  //     scaledSize: new window.google.maps.Size(40, 40),
  //     // fillColor: '#64be67',
  //     // fillOpacity: 1,
  //     // scale: 0.05, //to reduce the size of icons
  // };
  return isLoaded ? (
    <>
      {/* <div className='row'> */}
      {showMap === true && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={{
            lat: routes?.markedLocationRoute?.route?.customerDetails[0]
              .pickUpAddress.latitude,
            lng: routes?.markedLocationRoute?.route?.customerDetails[0]
              .pickUpAddress.longitude,
          }}
          zoom={12}
          onUnmount={onUnmount}
          // onClick={_onClick}
        >
          {/* <Marker
                        label={{text:"Headquarter", color: "#fff" , className: 'labels2'}}
                        // icon={positionMarker}
                        position={{
                            lat: reservation?.markedLocation?.reservation?.rideHistory[0].address.latitude, lng: reservation?.markedLocation?.reservation?.rideHistory[0].address.longitude
                        }}
                    /> */}
          {/* {
                    newArr &&
                    newArr.length > 0 &&
                    newArr.map((stop, i) => {
                        return(
                            stop.routeType === "Pick" ? (
                                <Marker
                                    label={{text:`Point ${i+1}`, color: "#fff" , className: 'labels2'}}
                                    // icon={positionMarker}
                                    position={{
                                        lat: stop.pickUpAddress.latitude, lng: stop.pickUpAddress.longitude
                                    }}
                                />
                            ) : (
                                <Marker
                                    label={{text:`Point ${i+1}`, color: "#fff" , className: 'labels2'}}
                                    // icon={positionMarker}
                                    position={{
                                        lat: stop.dropOffAddress.latitude, lng: stop.dropOffAddress.longitude
                                    }}
                                />
                            )
                        )
                    })
                } */}
          {dir3 &&
            dir3.length > 0 &&
            dir3.map((stop, i) => {
              return <DirectionsRenderer key={i} directions={stop} />;
            })}
          {/* <DirectionsRenderer
                    directions={dir3[0]}
                />
                <DirectionsRenderer
                    directions={dir3[1]}
                />
                <DirectionsRenderer
                    directions={dir3[2]}
                /> */}
          {/* {dir && 
                    <DirectionsRenderer
                        directions={dir}
                    />
                    }
                    {dir2 && 
                    <DirectionsRenderer
                        directions={dir2}
                    />
                    } */}
        </GoogleMap>
      )}
      {/* {
                routes?.markedLocationRoute?.route?.customerDetails &&
                routes?.markedLocationRoute?.route?.customerDetails.length > 0 &&
                routes?.markedLocationRoute?.route?.customerDetails.map((current,i) => {
                    return(
                        <div className="card" style={{width: "30%", marginLeft: "3%", height: "100px", float: 'left'}}>
                            <div className="card-body">
                                <h6 className="card-title m-b-15">Employee details</h6>
                            </div>
                        </div>
                    )
                })
            } */}
      {/* </div> */}
    </>
  ) : (
    <></>
  );
};

export default NavigationRoute;
