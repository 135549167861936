import axios from "axios";
import tokenService from "./tokenService";
import { SERVERADDRESS, SOCKET_SERVER_ADDRESS } from "../constants";

export const SOCKET_BASE_URL = SOCKET_SERVER_ADDRESS;
// export const BASE_URL =SERVERADDRESS

// Development
export const axiosPublic = axios.create({
  baseURL: `${SERVERADDRESS}`,
  headers: {
    "Content-Type": "application/json",
    accepts: "application/json",
  },
});

export const axiosPrivate = axios.create({
  baseURL: `${SERVERADDRESS}`,
  headers: {
    "Content-Type": "application/json",
    accepts: "application/json",

    Authorization: `Bearer ${tokenService.getLocalAccessToken()}`,
  },
});

export const axiosNoAuth = axios.create({
  baseURL: `${SERVERADDRESS}`,
  headers: {
    "Content-Type": "application/json",
    accepts: "application/json",

    Authorization: `Bearer ${tokenService.getLocalAccessToken()}`,
  },
});

// Request interceptor for API calls

axiosPrivate.interceptors.request.use((req) => {
  const bearerInReq = req.headers.Authorization;

  if (bearerInReq.includes("undefined")) {
    req.headers["Authorization"] =
      "Bearer " + tokenService.getLocalAccessToken();
  }

  // req.meta = req.meta || {};
  // req.meta.requestStartedAt = new Date().getTime();
  return req;
});

// Response interceptor for API calls
axiosPrivate.interceptors.response.use(
  (response) => {
    // console.log(response, "response");
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const { data } = await axiosPrivate.post(`/v1/auth/refresh-tokens`, {
          refreshToken: tokenService.getLocalRefreshToken(),
        });
        // console.log(data, "object retrieved");
        // dispatch(refreshToken(accessToken));

        tokenService.updateLocalAccessToken(data);
        originalRequest.headers["Authorization"] =
          "Bearer " + data.access.token;
        // console.log(originalRequest, "originalRequest");
        // here what does axiosPrivate(originalRequest) means that request is resent for api or what
        return axiosPrivate(originalRequest);
        // return axiosApiInstance(originalRequest);
      } catch (err) {
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

// axiosNoAuth.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async function (error) {
//     const originalRequest = error.config;
//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       try {
//         const { data } = await axiosPrivate.post(/auth/refresh-tokens, {
//           refreshToken: tokenService.getLocalRefreshToken(),
//         });

//         // dispatch(refreshToken(accessToken));

//         originalRequest.headers["Authorization"] = "Bearer " + data.token;
//         // tokenService.updateLocalAccessToken(data);
//         localStorage.removeItem("tokens");

//         return axiosPrivate(originalRequest);
//         // return axiosApiInstance(originalRequest);
//       } catch (err) {
//         return Promise.reject(err);
//       }
//     }
//     return Promise.reject(error);
//   }
// );

// export default axiosPrivate;
