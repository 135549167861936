/** @format */

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AssignDriverModal from "./AssignDriverModal";
import { useDispatch, useSelector } from "react-redux";
import { ROUTE_DETAILS_SUCCESS } from "../../../constants";
import AdminRouteDeleteModal from "./AdminRouteDeleteModal";
import {
  getAllRoutesOfCompany,
  reAssignRoteAction,
  updateRouteUnassigned,
  updateRouteById,
  getRouteandTripByIdV2,
  updateRouteTrip,
  undoRouteTrip,
  tripForDate,
  getAllRoutesOfCompanyV2,
} from "../../../actions/routeActions";
import { allDriversAvailableForRoutes } from "../../../actions/userActions";
import moment from "moment";
// import AdminBookingTodayBookingRideLogModal from './AdminBookingTodayBookingRideLogModal'
// import moment from "moment";
import { Accordion } from "react-bootstrap";
import RoutesTableAccordionRow from "./RoutesTableAccordionRow";
import UpdatingModal from "../../../components/UpdatingModal";
import { notifyFailure } from "../../../components/toast";
import { addRouteTrip } from "../../../actions/routeTripActions";
import AdminRouteDisableModal from "./AdminRouteDisableModal";
import AdminRouteReactivateModal from "./AdminRouteReactivateModal";

const RoutesTableAccordion = ({
  current,
  i,
  dropDownRow,
  setDropDownRow,
  editOrderHandler,
  // deleteRouteHandler,
  viewRouteHandler,
  selectRoute,
}) => {
  const routes = useSelector((state) => state.routes);
  const users = useSelector((state) => state.users);
  const { loading, routeDetails } = routes;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const deleteRouteHandler = (routeData) => {
    setSelectedRoute(routeData);
    setShowDeleteModal(true);
  };
  const [showStopModal, setShowStopModal] = useState(false);
  const [showReactivateModal, setShowReactivateModal] = useState(false);
  const stopRouteHandler = (routeData) => {
    setSelectedRoute(routeData);
    setShowStopModal(true);
  };
  const reactivateRouteHandler = (routeData) => {
    setSelectedRoute(routeData);
    setShowReactivateModal(true);
  };
  // const [showDeleteDropDown, setshowDeleteDropDown] = useState(false)

  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };

  const [showRideLogDropDown, setshowRideLogDropDown] = useState(false);

  const openRideLogDropDown = () => {
    setshowRideLogDropDown((showRideLogDropDown) => !showRideLogDropDown);
  };

  const [shift, setShift] = useState(null);
  const [showDropDown, setshowDropDown] = useState(false);
  const openDropDown = () => {
    // console.log(openDropDown, "main open drop oper wala")
    setshowDropDown((showDropDown) => !showDropDown);
    //   console.log(openDropDown, "main open drop neecha wala")
  };

  const [assignDropDown, setAssignDropDown] = useState(false);
  const openAssignDropDown = () => {
    setAssignDropDown((assignDropDown) => !assignDropDown);
  };

  const handleChange = (event) => {
    if (event.target.value === "Assigned") {
      // setValues({
      //   ...values,
      //   [e.target]: "Unassigned",
      // });
    }
    if (event.target.value === "Assigned") {
      openDropDown();
      // openDropDown.push({Assigned:event.target.value})
    }
    //     if(fieldName === 'date'){
    //       rideArr.push({date:event.target.value})
    //     }
    //     if(fieldName === 'time'){
    //       rideArr.push({time:event.target.value})
  };

  const createReturnRouteHandler = () => {
    dispatch({
      type: ROUTE_DETAILS_SUCCESS,
      payload: current,
    });
    navigate(`create-return/${current.id}`);
  };

  const auth = useSelector((state) => state.auth);
  const [distinctArray, setDistinctArray] = useState([]);
  const [distinctDaysArray, setDistinctDaysArray] = useState([]);

  useEffect(() => {
    const uniqueValuesSet = new Set();
    const uniqueValuesSet2 = new Set();
    const distinctArray = current?.customerDetails?.filter((obj) => {
      const value = obj?.type?.name;
      if (!uniqueValuesSet.has(value)) {
        uniqueValuesSet.add(value);
        return true;
      }
      return false;
    });

    setDistinctArray(distinctArray);
    current?.customerDetails?.forEach((obj) => {
      obj?.daysOfWeek?.forEach((day) => {
        if (day.active === true) {
          if (!uniqueValuesSet2.has(day.dayName.slice(0, 3))) {
            uniqueValuesSet2.add(day.dayName.slice(0, 3));
          }
        }
      });
    });
    const result = Array.from(uniqueValuesSet2);

    setDistinctDaysArray(result);
  }, [current]);

  const unassignedRoute = async (event) => {
    event.preventDefault();
    dispatch(
      updateRouteUnassigned(
        {
          routeId: current?.id,
          unAssigneeId: auth?.user?.id,
          endDate: moment().format().toString().substring(0, 19) + ".000Z",
        },
        successHandler
      )
    );
  };

  const sockets = useSelector((state) => state.sockets);
  const { socketInstance } = sockets;
  const successHandler = () => {
    // dispatch(
    //   getAllRoutesOfCompanyV2({
    //     companyId: auth.user.companyId.id,
    //     // limit: routes.limit,
    //     // page: routes.page,
    //   })
    // );
    dispatch(
      allDriversAvailableForRoutes({ companyId: auth.user.companyId.id })
    );
    // const chicagoDateOptions = {
    //   timeZone: "America/Chicago",
    //   year: "numeric",
    //   month: "2-digit",
    //   day: "2-digit",
    // };

    // const chicagoDate = new Date().toLocaleDateString(
    //   "en-US",
    //   chicagoDateOptions
    // );
    // const parts = chicagoDate.split("/");
    // const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
    socketInstance?.emit("send-route-req", {
      userId: auth.user.id,
      companyId: auth.user.companyId.id,
      id: current?.id,
      // today: formattedChicagoDate,
      today: selectedDate.format("YYYY-MM-DD"),
      role: "admin",
    });
    // notifySuccess("")
  };

  //   useEffect(() => {
  //     dispatch(getRouteandTripByIdV2({
  //         _id:params.id,
  //         today: moment().format('YYYY-MM-DD')
  //     }));
  // }, []);

  const [routeAndTripDetails, setRouteAndTripDetails] = useState(null);

  useEffect(() => {
    if (routeDetails) {
      setRouteAndTripDetails(routeDetails);
    }
  }, [routeDetails]);

  // const startTripHandler = () => {
  //   if (routes?.routeDetails?.route?.driverBookingStatus !== "Assigned") {
  //     notifyFailure("Driver not Assigned!");
  //   } else {
  //     dispatch(
  //       addRouteTrip({
  //         companyId: auth.user.companyId.id,
  //         driverId: routes?.routeDetails?.route?.driverId?.id,
  //         routeId: routes?.routeDetails?.route?.id,
  //         tripStartTime:
  //           moment().format().toString().substring(0, 19) + ".000Z",
  //       })
  //     );
  //   }
  //   // setTimeout(() => {
  //   //   dataLoaded();
  //   //   dispatch(
  //   //     getRouteandTripByIdV2({
  //   //       _id: params.id,
  //   //       today: moment(today).format("YYYY-MM-DD"),
  //   //     })
  //   //   );
  //   // }, 1500);
  //   // }}
  // };
  const startTripHandler = (shift) => {
    if (routes?.routeDetails?.route?.driverBookingStatus !== "Assigned") {
      notifyFailure("Driver not Assigned!");
    } else {
      dispatch(
        addRouteTrip(
          {
            companyId: auth.user.companyId.id,
            driverId:
              shift === "toggleValuesAM"
                ? driverAM?.id
                : shift === "toggleValuesPM"
                ? driverPM?.id
                : driverMID?.id,
            routeId: routes?.routeDetails?.route?.id,
            tripStartTime:
              selectedDate.format().toString().substring(0, 19) + ".000Z",
            shiftTime:
              shift === "toggleValuesAM"
                ? "AM"
                : shift === "toggleValuesPM"
                ? "PM"
                : "MID",
          },
          shift,
          successHandler
        )
      );
    }
  };
  const endTripHandler = (shift) => {
    const targetArray = eval(shift);
    if (Array.isArray(targetArray)) {
      dispatch(
        updateRouteTrip(
          {
            _id:
              shift === "toggleValuesAM"
                ? routeDetails?.tripAM?.id
                : shift === "toggleValuesPM"
                ? routeDetails?.tripPM?.id
                : routeDetails?.tripMID?.id,
            isCompleted: true,
          },
          successHandler
          // updateToggleValues
        )
      );
    }
  };

  const pickCustomerHandler = (shift, customerId, index, lat, lng) => {
    if (
      (shift === "toggleValuesAM" && !routeDetails.tripAM) ||
      (shift === "toggleValuesPM" && !routeDetails.tripPM) ||
      (shift === "toggleValuesMID" && !routeDetails.tripMID)
    ) {
      notifyFailure("Trip not started yet!");
      return;
    }
    // console.log("==>", customerId, shift[index]?.pick, shift[index]);
    // if (toggleValues[index]?.pick) {
    const targetArray = eval(shift);
    if (Array.isArray(targetArray)) {
      // console.log(
      //   "==>",
      //   customerId,
      //   targetArray[index]?.pick,
      //   targetArray[index]
      // );
      if (targetArray[index]?.pick) {
        dispatch(
          undoRouteTrip(
            {
              _id:
                shift === "toggleValuesAM"
                  ? routeDetails?.tripAM?.id
                  : shift === "toggleValuesPM"
                  ? routeDetails?.tripPM?.id
                  : routeDetails?.tripMID?.id,
              customerId: customerId,
              undoActions: "pick",
            },
            updateToggleValues
          )
        );
      } else {
        dispatch(
          updateRouteTrip(
            {
              _id:
                shift === "toggleValuesAM"
                  ? routeDetails?.tripAM?.id
                  : shift === "toggleValuesPM"
                  ? routeDetails?.tripPM?.id
                  : routeDetails?.tripMID?.id,
              customerId: customerId,
              pickUpTime:
                moment().format().toString().substring(0, 19) + ".000Z",
              arrivedTime:
                moment().format().toString().substring(0, 19) + ".000Z",
              // tripStatus: "Ongoing",
              pickUpLat: lat,
              pickUpLng: lng,
            },
            updateToggleValues
          )
        );
      }

      // updateToggleValues();
    }
  };

  const noshowCustomerHandler = (shift, customerId, index, lat, lng) => {
    if (
      (shift === "toggleValuesAM" && !routeDetails.tripAM) ||
      (shift === "toggleValuesPM" && !routeDetails.tripPM) ||
      (shift === "toggleValuesMID" && !routeDetails.tripMID)
    ) {
      notifyFailure("Trip not started yet!");
      return;
    }
    // console.log("==>", customerId, shift[index]?.pick, shift[index]);
    const targetArray = eval(shift);
    if (Array.isArray(targetArray)) {
      // console.log(
      //   "==>",
      //   customerId,
      //   targetArray[index]?.pick,
      //   targetArray[index]
      // );
      if (targetArray[index]?.noshow) {
        dispatch(
          undoRouteTrip(
            {
              _id:
                shift === "toggleValuesAM"
                  ? routeDetails?.tripAM?.id
                  : shift === "toggleValuesPM"
                  ? routeDetails?.tripPM?.id
                  : routeDetails?.tripMID?.id,
              customerId: customerId,
              undoActions: "noshow",
            },
            updateToggleValues
          )
        );
      } else {
        dispatch(
          updateRouteTrip(
            {
              _id:
                shift === "toggleValuesAM"
                  ? routeDetails?.tripAM?.id
                  : shift === "toggleValuesPM"
                  ? routeDetails?.tripPM?.id
                  : routeDetails?.tripMID?.id,
              customerId: customerId,
              pickUpTime:
                moment().format().toString().substring(0, 19) + ".000Z",
              arrivedTime:
                moment().format().toString().substring(0, 19) + ".000Z",
              pickUpLat: lat,
              pickUpLng: lng,
              dropOffTime:
                moment().format().toString().substring(0, 19) + ".000Z",
              dropOffLat: lat,
              dropOffLng: lng,
              customerCancelReason: "No Show",
            },
            updateToggleValues
          )
        );
      }

      // updateToggleValues();
    }
  };

  const dropCustomerHandler = (shift, customerId, index, lat, lng) => {
    if (
      (shift === "toggleValuesAM" && !routeDetails.tripAM) ||
      (shift === "toggleValuesPM" && !routeDetails.tripPM) ||
      (shift === "toggleValuesMID" && !routeDetails.tripMID)
    ) {
      notifyFailure("Trip not started yet!");
      return;
    }
    // if (toggleValues[index]?.drop) {
    const targetArray = eval(shift);
    if (Array.isArray(targetArray)) {
      if (targetArray[index]?.drop) {
        dispatch(
          undoRouteTrip(
            {
              _id:
                shift === "toggleValuesAM"
                  ? routeDetails?.tripAM?.id
                  : shift === "toggleValuesPM"
                  ? routeDetails?.tripPM?.id
                  : routeDetails?.tripMID?.id,
              customerId: customerId,
              undoActions: "dropoff",
            },
            updateToggleValues
          )
        );
      } else {
        dispatch(
          updateRouteTrip(
            {
              _id:
                shift === "toggleValuesAM"
                  ? routeDetails?.tripAM?.id
                  : shift === "toggleValuesPM"
                  ? routeDetails?.tripPM?.id
                  : routeDetails?.tripMID?.id,
              customerId: customerId,
              dropOffTime:
                moment().format().toString().substring(0, 19) + ".000Z",
              // tripStatus: "Completed",
              dropOffLat: lat,
              dropOffLng: lng,
            },
            updateToggleValues
          )
        );
      }
      // updateToggleValues();
    }
  };

  const removeCustomerHandler = (shift, customerId, index) => {
    const targetArray = eval(shift);
    if (Array.isArray(targetArray)) {
      // console.log(targetArray, customerId, "customerId");
      const newArray = targetArray
        .filter((item) => item?.isComing === false)
        .map((item) => item.id);
      if (targetArray[index]?.isComing) {
        // console.log(newArray, "newArray");
        newArray.push(customerId);
        // console.log(newArray, "newArray1");
        dispatch(
          tripForDate(
            {
              companyId: auth.user.companyId.id,
              // driverId: routes?.routeDetails?.route?.driverId?.id,
              routeId: routes?.routeDetails?.route?.id,
              tripStartTime:
                moment(selectedDate).format().toString().substring(0, 19) +
                ".000Z",
              shiftTime:
                shift === "toggleValuesAM"
                  ? "AM"
                  : shift === "toggleValuesPM"
                  ? "PM"
                  : "MID",
              // notComing: [customerId],
              notComing: newArray,
              tripStartTime:
                selectedDate.format().toString().substring(0, 19) + ".000Z",
            },
            shift,
            successHandler
          )
        );
      } else {
        // console.log(newArray, "newArray2", customerId);
        // newArray.filter((x) => x != customerId);
        let secondArr = [];
        newArray.forEach((e) => {
          if (e != customerId) {
            secondArr.push(e);
          }
        });
        // console.log(newArray, secondArr, "newArray3");
        dispatch(
          tripForDate(
            {
              companyId: auth.user.companyId.id,
              // driverId: routes?.routeDetails?.route?.driverId?.id,
              routeId: routes?.routeDetails?.route?.id,
              tripStartTime:
                moment(selectedDate).format().toString().substring(0, 19) +
                ".000Z",
              shiftTime:
                shift === "toggleValuesAM"
                  ? "AM"
                  : shift === "toggleValuesPM"
                  ? "PM"
                  : "MID",
              // notComing: [],
              notComing: secondArr,
              tripStartTime:
                selectedDate.format().toString().substring(0, 19) + ".000Z",
            },
            shift,
            successHandler
          )
        );
      }
    }
  };

  const [toggleValues, setToggleValues] = useState([]);
  const [toggleValuesAM, setToggleValuesAM] = useState([]);
  const [toggleValuesPM, setToggleValuesPM] = useState([]);
  const [toggleValuesMID, setToggleValuesMID] = useState([]);
  const [driverAM, setDriverAM] = useState();
  const [driverPM, setDriverPM] = useState();
  const [driverMID, setDriverMID] = useState();
  // const [driverUse, setDriverUse] = useState();
  useEffect(() => {
    if (routeDetails) {
      // let arr = [];
      // routeDetails?.route?.customerDetails?.forEach((e) => {
      //   arr.push({
      //     pick:
      //       routes?.routeDetails?.trip === null
      //         ? false
      //         : routes?.routeDetails?.trip?.customersDetails?.some(
      //             (item) =>
      //               item?.customerId == e?.customerId?.id &&
      //               item.hasOwnProperty("pickUpTime") &&
      //               !item.hasOwnProperty("customerCancelReason")
      //           ),
      //     noshow:
      //       routes?.routeDetails?.trip === null
      //         ? false
      //         : routes?.routeDetails?.trip?.customersDetails?.some(
      //             (item) =>
      //               item?.customerId == e?.customerId?.id &&
      //               item.hasOwnProperty("customerCancelReason")
      //           ),
      //     drop:
      //       routes?.routeDetails?.trip === null
      //         ? false
      //         : routes?.routeDetails?.trip?.customersDetails?.some(
      //             (item) =>
      //               item?.customerId == e?.customerId?.id &&
      //               item.hasOwnProperty("dropOffTime") &&
      //               !item.hasOwnProperty("customerCancelReason")
      //           ),
      //   });
      // });
      // setToggleValues(arr);
      let arrAM = [];
      let arrPM = [];
      let arrMID = [];
      routeDetails?.route?.customerDetailsAM?.forEach((e) => {
        arrAM.push({
          id: e?.customerId,
          pick:
            routes?.routeDetails?.tripAM === null
              ? false
              : routes?.routeDetails?.tripAM?.customersDetails?.some(
                  (item) =>
                    item?.customerId == e?.customerId &&
                    item.hasOwnProperty("pickUpTime") &&
                    !item.hasOwnProperty("customerCancelReason")
                ),
          noshow:
            routes?.routeDetails?.tripAM === null
              ? false
              : routes?.routeDetails?.tripAM?.customersDetails?.some(
                  (item) =>
                    item?.customerId == e?.customerId &&
                    item.hasOwnProperty("customerCancelReason")
                ),
          drop:
            routes?.routeDetails?.tripAM === null
              ? false
              : routes?.routeDetails?.tripAM?.customersDetails?.some(
                  (item) =>
                    item?.customerId == e?.customerId &&
                    item.hasOwnProperty("dropOffTime") &&
                    !item.hasOwnProperty("customerCancelReason")
                ),
          isComing:
            routes?.routeDetails?.tripAM === null
              ? true
              : routes?.routeDetails?.tripAM?.customersDetails?.find(
                  (item) => item?.customerId == e?.customerId
                )?.isComing,
        });
      });
      routeDetails?.route?.customerDetailsPM?.forEach((e) => {
        arrPM.push({
          id: e?.customerId,
          pick:
            routes?.routeDetails?.tripPM === null
              ? false
              : routes?.routeDetails?.tripPM?.customersDetails?.some(
                  (item) =>
                    item?.customerId == e?.customerId &&
                    item.hasOwnProperty("pickUpTime") &&
                    !item.hasOwnProperty("customerCancelReason")
                ),
          noshow:
            routes?.routeDetails?.tripPM === null
              ? false
              : routes?.routeDetails?.tripPM?.customersDetails?.some(
                  (item) =>
                    item?.customerId == e?.customerId &&
                    item.hasOwnProperty("customerCancelReason")
                ),
          drop:
            routes?.routeDetails?.tripPM === null
              ? false
              : routes?.routeDetails?.tripPM?.customersDetails?.some(
                  (item) =>
                    item?.customerId == e?.customerId &&
                    item.hasOwnProperty("dropOffTime") &&
                    !item.hasOwnProperty("customerCancelReason")
                ),
          isComing:
            routes?.routeDetails?.tripPM === null
              ? true
              : routes?.routeDetails?.tripPM?.customersDetails?.find(
                  (item) => item?.customerId == e?.customerId
                )?.isComing,
        });
      });
      routeDetails?.route?.customerDetailsMID?.forEach((e) => {
        // console.log(
        //   routes?.routeDetails?.tripMID?.customersDetails?.find(
        //     (item) => item?.customerId == e?.customerId
        //   ),
        //   "ffffffffffffffffffff",
        //   e?.customerId,
        //   routes?.routeDetails?.tripMID?.customersDetails
        // );
        arrMID.push({
          id: e?.customerId,
          pick:
            routes?.routeDetails?.tripMID === null
              ? false
              : routes?.routeDetails?.tripMID?.customersDetails?.some(
                  (item) =>
                    item?.customerId == e?.customerId &&
                    item.hasOwnProperty("pickUpTime") &&
                    !item.hasOwnProperty("customerCancelReason")
                ),
          noshow:
            routes?.routeDetails?.tripMID === null
              ? false
              : routes?.routeDetails?.tripMID?.customersDetails?.some(
                  (item) =>
                    item?.customerId == e?.customerId &&
                    item.hasOwnProperty("customerCancelReason")
                ),
          drop:
            routes?.routeDetails?.tripMID === null
              ? false
              : routes?.routeDetails?.tripMID?.customersDetails?.some(
                  (item) =>
                    item?.customerId == e?.customerId &&
                    item.hasOwnProperty("dropOffTime") &&
                    !item.hasOwnProperty("customerCancelReason")
                ),
          isComing:
            routes?.routeDetails?.tripMID === null
              ? true
              : routes?.routeDetails?.tripMID?.customersDetails?.find(
                  (item) => item?.customerId == e?.customerId
                )?.isComing,
        });
      });
      setToggleValuesAM(arrAM);
      setToggleValuesPM(arrPM);
      setToggleValuesMID(arrMID);
      setDriverAM(
        routeDetails?.tripAM?.driverId
          ? users?.users?.find((x) => x.id == routeDetails?.tripAM?.driverId)
          : routeDetails?.route?.driverId
      );
      setDriverPM(
        routeDetails?.tripPM?.driverId
          ? users?.users?.find((x) => x.id == routeDetails?.tripPM?.driverId)
          : routeDetails?.route?.driverId
      );
      setDriverMID(
        routeDetails?.tripMID?.driverId
          ? users?.users?.find((x) => x.id == routeDetails?.tripMID?.driverId)
          : routeDetails?.route?.driverId
      );
    }
  }, [routeDetails]);

  const updateToggleValues = () => {
    // let arr = [];
    // routeDetails?.route?.customerDetails?.forEach((e) => {
    //   arr.push({
    //     pick:
    //       routes?.routeDetails?.trip === null
    //         ? false
    //         : routes?.routeDetails?.trip?.customersDetails?.some(
    //             (item) =>
    //               item?.customerId == e?.customerId?.id &&
    //               item.hasOwnProperty("pickUpTime") &&
    //               !item.hasOwnProperty("customerCancelReason")
    //           ),
    //     noshow:
    //       routes?.routeDetails?.trip === null
    //         ? false
    //         : routes?.routeDetails?.trip?.customersDetails?.some(
    //             (item) =>
    //               item?.customerId == e?.customerId?.id &&
    //               item.hasOwnProperty("customerCancelReason")
    //           ),
    //     drop:
    //       routes?.routeDetails?.trip === null
    //         ? false
    //         : routes?.routeDetails?.trip?.customersDetails?.some(
    //             (item) =>
    //               item?.customerId == e?.customerId?.id &&
    //               item.hasOwnProperty("dropOffTime") &&
    //               !item.hasOwnProperty("customerCancelReason")
    //           ),
    //   });
    // });
    // setToggleValues(arr);
    let arrAM = [];
    let arrPM = [];
    let arrMID = [];
    routeDetails?.route?.customerDetailsAM?.forEach((e) => {
      arrAM.push({
        id: e?.customerId,
        pick:
          routes?.routeDetails?.tripAM === null
            ? false
            : routes?.routeDetails?.tripAM?.customersDetails?.some(
                (item) =>
                  item?.customerId == e?.customerId &&
                  item.hasOwnProperty("pickUpTime") &&
                  !item.hasOwnProperty("customerCancelReason")
              ),
        noshow:
          routes?.routeDetails?.tripAM === null
            ? false
            : routes?.routeDetails?.tripAM?.customersDetails?.some(
                (item) =>
                  item?.customerId == e?.customerId &&
                  item.hasOwnProperty("customerCancelReason")
              ),
        drop:
          routes?.routeDetails?.tripAM === null
            ? false
            : routes?.routeDetails?.tripAM?.customersDetails?.some(
                (item) =>
                  item?.customerId == e?.customerId &&
                  item.hasOwnProperty("dropOffTime") &&
                  !item.hasOwnProperty("customerCancelReason")
              ),
        isComing:
          routes?.routeDetails?.tripAM === null
            ? true
            : routes?.routeDetails?.tripAM?.customersDetails?.find(
                (item) => item?.customerId == e?.customerId
              )?.isComing,
      });
    });
    routeDetails?.route?.customerDetailsPM?.forEach((e) => {
      arrPM.push({
        id: e?.customerId,
        pick:
          routes?.routeDetails?.tripPM === null
            ? false
            : routes?.routeDetails?.tripPM?.customersDetails?.some(
                (item) =>
                  item?.customerId == e?.customerId &&
                  item.hasOwnProperty("pickUpTime") &&
                  !item.hasOwnProperty("customerCancelReason")
              ),
        noshow:
          routes?.routeDetails?.tripPM === null
            ? false
            : routes?.routeDetails?.tripPM?.customersDetails?.some(
                (item) =>
                  item?.customerId == e?.customerId &&
                  item.hasOwnProperty("customerCancelReason")
              ),
        drop:
          routes?.routeDetails?.tripPM === null
            ? false
            : routes?.routeDetails?.tripPM?.customersDetails?.some(
                (item) =>
                  item?.customerId == e?.customerId &&
                  item.hasOwnProperty("dropOffTime") &&
                  !item.hasOwnProperty("customerCancelReason")
              ),
        isComing:
          routes?.routeDetails?.tripPM === null
            ? true
            : routes?.routeDetails?.tripPM?.customersDetails?.find(
                (item) => item?.customerId == e?.customerId
              )?.isComing,
      });
    });
    routeDetails?.route?.customerDetailsMID?.forEach((e) => {
      arrMID.push({
        id: e?.customerId,
        pick:
          routes?.routeDetails?.tripMID === null
            ? false
            : routes?.routeDetails?.tripMID?.customersDetails?.some(
                (item) =>
                  item?.customerId == e?.customerId &&
                  item.hasOwnProperty("pickUpTime") &&
                  !item.hasOwnProperty("customerCancelReason")
              ),
        noshow:
          routes?.routeDetails?.tripMID === null
            ? false
            : routes?.routeDetails?.tripMID?.customersDetails?.some(
                (item) =>
                  item?.customerId == e?.customerId &&
                  item.hasOwnProperty("customerCancelReason")
              ),
        drop:
          routes?.routeDetails?.tripMID === null
            ? false
            : routes?.routeDetails?.tripMID?.customersDetails?.some(
                (item) =>
                  item?.customerId == e?.customerId &&
                  item.hasOwnProperty("dropOffTime") &&
                  !item.hasOwnProperty("customerCancelReason")
              ),
        isComing:
          routes?.routeDetails?.tripMID === null
            ? true
            : routes?.routeDetails?.tripMID?.customersDetails?.find(
                (item) => item?.customerId == e?.customerId
              )?.isComing,
      });
    });
    setToggleValuesAM(arrAM);
    setToggleValuesPM(arrPM);
    setToggleValuesMID(arrMID);
    setDriverAM(
      routeDetails?.tripAM?.driverId
        ? users?.users?.find((x) => x.id == routeDetails?.tripAM?.driverId)
        : routeDetails?.route?.driverId
    );
    setDriverPM(
      routeDetails?.tripPM?.driverId
        ? users?.users?.find((x) => x.id == routeDetails?.tripPM?.driverId)
        : routeDetails?.route?.driverId
    );
    setDriverMID(
      routeDetails?.tripMID?.driverId
        ? users?.users?.find((x) => x.id == routeDetails?.tripMID?.driverId)
        : routeDetails?.route?.driverId
    );
    successHandler();
  };
  // const updatePick = (id, customerId) => {
  //   dispatch(
  //     updateRouteTrip({
  //       _id: id,
  //       customerId: customerId,
  //       arrivedTime: moment().format().toString().substring(0, 19) + ".000Z",
  //       pickUpTime: moment().format().toString().substring(0, 19) + ".000Z",
  //       pickUpLat: 0,
  //       pickUpLng: 0,
  //     })
  //   );
  // };
  // const undoPick = (id, customerId) => {
  //   dispatch(
  //     undoRouteTrip({
  //       _id: id,
  //       customerId: customerId,
  //       undoActions: "pick",
  //     })
  //   );
  // };

  // console.log(toggleValues, "toggleValues");
  const handleDate = (event) => {
    setSelectedDate(moment(event.target.value));
    dispatch(
      getRouteandTripByIdV2({
        _id: current.id,
        today: moment(event.target.value).format("YYYY-MM-DD"),
      })
    );
  };

  const [selectedDate, setSelectedDate] = useState(moment());
  const customerDetailsAM = current?.customerDetailsAM || [];
  const customerDetailsMID = current?.customerDetailsMID || [];
  const customerDetailsPM = current?.customerDetailsPM || [];
  const allCustomerDetails = [
    ...customerDetailsAM,
    ...customerDetailsMID,
    ...customerDetailsPM,
  ];
  const activeCustomerIds = allCustomerDetails
    .filter((customer) => customer.isActive)
    .map((customer) => customer?.customerId?.id);
  const uniqueActiveCustomerIds = [...new Set(activeCustomerIds)];
  return (
    <>
      {loading && routes?.routeDetails?.route?.id && <UpdatingModal />}
      <Accordion.Item eventKey={i} onClick={() => selectRoute(current)}>
        <Accordion.Header
        // onClick={(e) => e.stopPropagation()}
        // className="accordion-header"
        // id="headingOne"
        // onClick={() => {
        //   // if (!isOpen) {
        //   //   setIsOpen(true);
        //   setLoading(true);
        //   dispatch(
        //     getRouteandTripByIdV2(
        //       {
        //         _id: current.id,
        //         today: moment().format("YYYY-MM-DD"),
        //       },
        //       successCB
        //     )
        //   );
        //   // } else {
        //   //   setIsOpen(false);
        //   // }
        // }}
        >
          <div className="d-flex justify-content-between">
            <div className="col-md-3">
              <h2
                className=" mb-0 f-16 text-capitalize font-weight-bold"
                onClick={(e) => e.stopPropagation()}
              >
                {/* <svg className="svg-inline--fa fa-car fa-w-16 text-success" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="car" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M499.99 176h-59.87l-16.64-41.6C406.38 91.63 365.57 64 319.5 64h-127c-46.06 0-86.88 27.63-103.99 70.4L71.87 176H12.01C4.2 176-1.53 183.34.37 190.91l6 24C7.7 220.25 12.5 224 18.01 224h20.07C24.65 235.73 16 252.78 16 272v48c0 16.12 6.16 30.67 16 41.93V416c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h256v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-54.07c9.84-11.25 16-25.8 16-41.93v-48c0-19.22-8.65-36.27-22.07-48H494c5.51 0 10.31-3.75 11.64-9.09l6-24c1.89-7.57-3.84-14.91-11.65-14.91zm-352.06-17.83c7.29-18.22 24.94-30.17 44.57-30.17h127c19.63 0 37.28 11.95 44.57 30.17L384 208H128l19.93-49.83zM96 319.8c-19.2 0-32-12.76-32-31.9S76.8 256 96 256s48 28.71 48 47.85-28.8 15.95-48 15.95zm320 0c-19.2 0-48 3.19-48-15.95S396.8 256 416 256s32 12.76 32 31.9-12.8 31.9-32 31.9z"></path></svg> */}
                <i
                  className="fa fa-car fa-w-16 text-success"
                  // onClick={() => {
                  //   dispatch(
                  //     getRouteandTripByIdV2({
                  //       _id: current.id,
                  //       today: moment().format("YYYY-MM-DD"),
                  //     })
                  //   );
                  //   viewRouteHandler(current);
                  // }}
                ></i>{" "}
                Route # {current?.routeNumber}{" "}
                {current?.routeNumberReturn && "( Return )"}
              </h2>
            </div>
            <div className="col-md-3"></div>
            <div className="col-md-3"></div>
            {/* <div className="col-md-3">
              <h2
                className=" mb-0 f-16 text-capitalize font-weight-bold"
                onClick={(e) => e.stopPropagation()}
              >
                <a data-toggle="tooltip" title="Re-assign Driver">
                  <i
                    className="fa fa-edit fa-w-16 text-success"
                    data-toggle="modal"
                    data-target="#myDriver"
                    onClick={openAssignDropDown}
                  ></i>
                </a>
                Driver Name:{" "}
                {current.driverId ? (
                  <>
                    {current.driverId.firstName} {current.driverId?.lastName}
                  </>
                ) : (
                  "-"
                )}
              </h2>
            </div> */}
            <div className="col-md-3">
              <h2 className=" mb-0 f-16 text-capitalize font-weight-bold">
                {/* <svg className="svg-inline--fa fa-users fa-w-20 text-success" data-toggle="tooltip" title="Total Passengers: 3" aria-labelledby="svg-inline--fa-title-Wz3euveUtHb7" data-prefix="fa" data-icon="users" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg=""><title id="svg-inline--fa-title-Wz3euveUtHb7">Total Passengers: 3</title><path fill="currentColor" d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"></path></svg> */}
                <i
                  className="fa fa-users fa-w-16 text-success"
                  data-toggle="tooltip"
                  title="Total Passengers"
                ></i>{" "}
                Total Passengers:{" "}
                {/* {Math.max(
                  current?.customerDetailsAM?.length,
                  current?.customerDetailsMID?.length,
                  current?.customerDetailsPM?.length
                )} */}
                {uniqueActiveCustomerIds.length}
              </h2>
            </div>
            {/* <div>
              <h2 className=" mb-0 f-16 text-capitalize font-weight-bold">

                <i className="fa fa-clock fa-w-16 text-success"></i> Shift Time:
                {current.shiftTime}
              </h2>
            </div> */}
          </div>
        </Accordion.Header>
        {/* <div
          id="collapseOne"
          className={`accordion-collapse collapse ${
            showAccordion === i ? "show" : ""
          }`}
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
          // style=""
        > */}
        <Accordion.Body>
          {/* <div className="col-md-12">
            <div className="flex-grow-1 ">
              <div className="d-flex justify-content-center">
                <div className="row">
                  <ul className="date-display d-flex align-items-center">
                    <button
                      className="btn btn-secondary py-0"
                      onClick={() => {
                        setSelectedDate(
                          moment(selectedDate).subtract(1, "days")
                        );
                        dispatch(
                          getRouteandTripByIdV2({
                            _id: current.id,
                            today: moment(selectedDate)
                              .subtract(1, "days")
                              .format("YYYY-MM-DD"),
                          })
                        );
                      }}
                    >
                      <i
                        className="fa fa-angle-left"
                        onClick={() =>
                          setSelectedDate((prev) => prev.subtract(1, "days"))
                        }
                      ></i>
                    </button>
                    <h6 className="pl-3 pr-3 m-0" id="date-info">
                      <input
                        type="date"
                        className="form-control height-35 f-14"
                        value={selectedDate.format("YYYY-MM-DD")}
                        onChange={handleDate}
                      />
                    </h6>
                    <button
                      className="btn  btn-secondary py-0"
                      onClick={() => {
                        setSelectedDate(moment(selectedDate).add(1, "days"));
                        dispatch(
                          getRouteandTripByIdV2({
                            _id: current.id,
                            today: moment(selectedDate)
                              .add(1, "days")
                              .format("YYYY-MM-DD"),
                          })
                        );
                      }}
                    >
                      <i className="fa fa-angle-right"></i>
                    </button>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-md-12">
            <div className="flex-grow-1">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-center w-100">
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp;
                  <ul className="date-display d-flex align-items-center">
                    <button
                      className="btn btn-secondary py-0"
                      onClick={() => {
                        setSelectedDate(
                          moment(selectedDate).subtract(1, "days")
                        );
                        dispatch(
                          getRouteandTripByIdV2({
                            _id: current.id,
                            today: moment(selectedDate)
                              .subtract(1, "days")
                              .format("YYYY-MM-DD"),
                          })
                        );
                      }}
                    >
                      <i
                        className="fa fa-angle-left"
                        onClick={() =>
                          setSelectedDate((prev) => prev.subtract(1, "days"))
                        }
                      ></i>
                    </button>
                    <h6 className="pl-3 pr-3 m-0" id="date-info">
                      <input
                        type="date"
                        className="form-control height-35 f-14"
                        value={selectedDate.format("YYYY-MM-DD")}
                        onChange={handleDate}
                      />
                    </h6>
                    <button
                      className="btn btn-secondary py-0"
                      onClick={() => {
                        setSelectedDate(moment(selectedDate).add(1, "days"));
                        dispatch(
                          getRouteandTripByIdV2({
                            _id: current.id,
                            today: moment(selectedDate)
                              .add(1, "days")
                              .format("YYYY-MM-DD"),
                          })
                        );
                      }}
                    >
                      <i className="fa fa-angle-right"></i>
                    </button>
                  </ul>
                </div>
                {auth?.user?.permissionId?.routes?.delete === true && (
                  <>
                    {!current.disable ? (
                      <button
                        className="btn btn-info ml-2"
                        onClick={() => stopRouteHandler(current.id)}
                        disabled={current.disable}
                      >
                        Stop
                      </button>
                    ) : (
                      <button
                        className="btn btn-info ml-2"
                        onClick={() => reactivateRouteHandler(current.id)}
                        disabled={!current.disable}
                      >
                        Reactivate
                      </button>
                    )}
                    <button
                      className="btn btn-warning ml-2"
                      onClick={() => deleteRouteHandler(current.id)}
                    >
                      Delete
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
          <br />
          {routeDetails?.route?.customerDetailsAM?.length > 0 && (
            <>
              <div className=" p-2" style={{ background: "#e5e5e5" }}>
                <div className="d-flex justify-content-between">
                  <div className="col-md-3">
                    <h2
                      className=" mb-0 f-16 text-capitalize font-weight-bold"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <a data-toggle="tooltip" title="Re-assign Driver">
                        {/* <svg className="svg-inline--fa fa-edit fa-w-18 text-success" data-toggle="modal" data-target="#myDriver" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="edit" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><path fill="currentColor" d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"></path></svg> */}
                        <i
                          className="fa fa-edit fa-w-16 text-success"
                          data-toggle="modal"
                          data-target="#myDriver"
                          onClick={() => {
                            setShift("toggleValuesAM");
                            openAssignDropDown();
                          }}
                        ></i>
                      </a>
                      Driver Name:{" "}
                      {driverAM ? (
                        <>
                          {driverAM?.firstName} {driverAM?.lastName}
                        </>
                      ) : (
                        "-"
                      )}
                    </h2>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="flex-grow-1 ">
                      <div className="d-flex justify-content-center">
                        <div className="row">
                          <ul className="date-display d-flex align-items-center">
                            <button
                              className="btn btn-secondary py-0"
                              onClick={() =>
                                setSelectedDate(
                                  moment(selectedDate).subtract(1, "days")
                                )
                              }
                            >
                              <i
                                className="fa fa-angle-left"
                                onClick={() =>
                                  setSelectedDate((prev) =>
                                    prev.subtract(1, "days")
                                  )
                                }
                              ></i>
                            </button>
                            <h6 className="pl-3 pr-3 m-0" id="date-info">
                              {selectedDate.format("ddd, MMM DD, YYYY")}
                            </h6>
                            <button
                              className="btn  btn-secondary py-0"
                              onClick={() =>
                                setSelectedDate(
                                  moment(selectedDate).add(1, "days")
                                )
                              }
                            >
                              <i className="fa fa-angle-right"></i>
                            </button>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="col-md-3">
              <h2
                className=" mb-0 f-16 text-capitalize font-weight-bold"
                onClick={(e) => e.stopPropagation()}
              >
                <a data-toggle="tooltip" title="Re-assign Driver">
                  <i
                    className="fa fa-edit fa-w-16 text-success"
                    data-toggle="modal"
                    data-target="#myDriver"
                    onClick={openAssignDropDown}
                  ></i>
                </a>
                Driver Name:{" "}
                {current.driverId ? (
                  <>
                    {current.driverId.firstName} {current.driverId?.lastName}
                  </>
                ) : (
                  "-"
                )}
              </h2>
            </div> */}
                  <div className="col-md-3">
                    <div className="d-flex justify-content-center">
                      <h2 className=" mb-0 f-16 text-capitalize font-weight-bold">
                        {/* <svg className="svg-inline--fa fa-clock fa-w-16 text-success" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="clock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256,8C119,8,8,119,8,256S119,504,256,504,504,393,504,256,393,8,256,8Zm92.49,313h0l-20,25a16,16,0,0,1-22.49,2.5h0l-67-49.72a40,40,0,0,1-15-31.23V112a16,16,0,0,1,16-16h32a16,16,0,0,1,16,16V256l58,42.5A16,16,0,0,1,348.49,321Z"></path></svg> */}
                        <i className="fa fa-clock fa-w-16 text-success"></i>{" "}
                        Shift Time: {"AM"}
                      </h2>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="d-flex justify-content-center">
                      <h2 className=" mb-0 f-16 text-capitalize font-weight-bold">
                        {/* <svg className="svg-inline--fa fa-clock fa-w-16 text-success" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="clock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256,8C119,8,8,119,8,256S119,504,256,504,504,393,504,256,393,8,256,8Zm92.49,313h0l-20,25a16,16,0,0,1-22.49,2.5h0l-67-49.72a40,40,0,0,1-15-31.23V112a16,16,0,0,1,16-16h32a16,16,0,0,1,16,16V256l58,42.5A16,16,0,0,1,348.49,321Z"></path></svg> */}
                        <i className="fa fa-calendar-alt fa-w-16 text-success"></i>{" "}
                        Status:{" "}
                        {!routeDetails?.tripAM
                          ? "Scheduled"
                          : routeDetails?.tripAM?.isCompleted
                          ? "Completed"
                          : routeDetails?.tripAM?.tripStartTime
                          ? "In Progress"
                          : "Scheduled"}
                      </h2>
                    </div>
                  </div>

                  {/* <div>
              <h2 className=" mb-0 f-16 text-capitalize font-weight-bold">

                <i className="fa fa-clock fa-w-16 text-success"></i> Shift Time:
                {current.shiftTime}
              </h2>
            </div> */}
                  <div className="col-md-3">
                    <div className="d-flex justify-content-end">
                      {routeDetails?.tripAM?.isCompleted === true ? (
                        <button className="btn btn-success py-0">
                          Completed
                        </button>
                      ) : routeDetails?.tripAM?.tripStartTime ? (
                        <button
                          className="btn btn-warning py-0"
                          onClick={() => endTripHandler("toggleValuesAM")}
                        >
                          Ride End
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary py-0"
                          onClick={() => startTripHandler("toggleValuesAM")}
                        >
                          Start
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-body">
                <table
                  className="table border w-100 dataTable no-footer"
                  id="leads-table"
                  role="grid"
                  aria-describedby="leads-table_info"
                  style={{ overflowX: "auto" }}
                >
                  <thead className="card-header">
                    <tr role="row">
                      {/* <th
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowspan="1"
                    colspan="1"
                    aria-label="Mobile: activate to sort column ascending"
                  >
                    Route#
                  </th> */}
                      <th
                        title="Customer Name"
                        className="sorting_disabled border-right align-table-header"
                        rowspan="1"
                        colspan="1"
                        aria-label="Next Follow Up"
                        // onClick={startTripHandler}
                      >
                        Customer Name
                      </th>
                      <th
                        title="Type"
                        className="sorting border-right align-table-header"
                        tabIndex="0"
                        aria-controls="leads-table"
                        rowspan="1"
                        colspan="1"
                        aria-label="Lead Agent: activate to sort column ascending"
                      >
                        Type
                      </th>
                      <th
                        title="Pickup"
                        className="sorting border-right align-table-header"
                        tabIndex="0"
                        aria-controls="leads-table"
                        rowspan="1"
                        colspan="1"
                        aria-label="Lead Agent: activate to sort column ascending"
                      >
                        Pickup Time
                      </th>
                      <th
                        title="Pickup Drop Address"
                        className="sorting border-right w-30 extraWidth align-table-header"
                        tabIndex="0"
                        aria-controls="leads-table"
                        rowspan="1"
                        colspan="1"
                        aria-label="Lead Agent: activate to sort column ascending"
                      >
                        Pickup Drop Address
                      </th>
                      <th
                        title="Drop Off Time"
                        className="sorting border-right align-table-header"
                        tabIndex="0"
                        aria-controls="leads-table"
                        rowspan="1"
                        colspan="1"
                        aria-label="Lead Agent: activate to sort column ascending"
                      >
                        Drop Off Time
                      </th>
                      <th
                        title="Action"
                        className="text-right pr-20 sorting_disabled border-right align-table-header"
                        rowspan="1"
                        colspan="1"
                        aria-label="Action"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  {routeDetails && (
                    <tbody>
                      {" "}
                      {routeDetails?.route?.customerDetailsAM?.map((x, i) => {
                        if (current.id === routes.routeDetails?.route?.id) {
                          // if (
                          //   !x?.dateRange[x?.dateRange.length - 1]?.end ||
                          //   new Date(x?.dateRange[x?.dateRange.length - 1]?.end)
                          //     ?.toISOString()
                          //     .split("T")[0] +
                          //     "T00:00:00.000Z" >
                          //     new Date(selectedDate.format("YYYY-MM-DD"))
                          //       .toISOString()
                          //       .split("T")[0] +
                          //       "T00:00:00.000Z"
                          // )
                          if (
                            (!x?.dateRange[x?.dateRange.length - 1]?.end &&
                              x.dateRange.some(
                                ({ start }) =>
                                  new Date(start).toISOString().split("T")[0] +
                                    "T00:00:00.000Z" <=
                                  new Date(selectedDate.format("YYYY-MM-DD"))
                                    .toISOString()
                                    .split("T")[0] +
                                    "T00:00:00.000Z"
                              )) ||
                            (x?.dateRange[x?.dateRange.length - 1]?.end &&
                              x.dateRange.some(
                                ({ start, end }) =>
                                  new Date(start).toISOString().split("T")[0] +
                                    "T00:00:00.000Z" <=
                                    new Date(selectedDate.format("YYYY-MM-DD"))
                                      .toISOString()
                                      .split("T")[0] +
                                      "T00:00:00.000Z" &&
                                  new Date(end).toISOString().split("T")[0] +
                                    "T00:00:00.000Z" >
                                    new Date(selectedDate.format("YYYY-MM-DD"))
                                      .toISOString()
                                      .split("T")[0] +
                                      "T00:00:00.000Z"
                              ))
                          ) {
                            return (
                              <RoutesTableAccordionRow
                                route={current}
                                current={x}
                                i={i}
                                removeCustomerHandler={removeCustomerHandler}
                                pickCustomerHandler={pickCustomerHandler}
                                noshowCustomerHandler={noshowCustomerHandler}
                                dropCustomerHandler={dropCustomerHandler}
                                startTripHandler={startTripHandler}
                                endTripHandler={endTripHandler}
                                toggleValues={toggleValuesAM}
                                toggleValuesAM={toggleValuesAM}
                                updateToggleValues={updateToggleValues}
                                toggleType="toggleValuesAM"
                              />
                            );
                          }
                        } else {
                          return <></>;
                        }
                      })}
                    </tbody>
                  )}
                </table>
              </div>
            </>
          )}

          {routeDetails?.route?.customerDetailsMID?.length > 0 && (
            <>
              <div className="p-2" style={{ background: "#e5e5e5" }}>
                <div className="d-flex justify-content-between">
                  <div className="col-md-3">
                    <h2
                      className=" mb-0 f-16 text-capitalize font-weight-bold"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <a data-toggle="tooltip" title="Re-assign Driver">
                        {/* <svg className="svg-inline--fa fa-edit fa-w-18 text-success" data-toggle="modal" data-target="#myDriver" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="edit" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><path fill="currentColor" d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"></path></svg> */}
                        <i
                          className="fa fa-edit fa-w-16 text-success"
                          data-toggle="modal"
                          data-target="#myDriver"
                          onClick={() => {
                            setShift("toggleValuesMID");
                            openAssignDropDown();
                          }}
                        ></i>
                      </a>
                      Driver Name:{" "}
                      {driverMID ? (
                        <>
                          {driverMID?.firstName} {driverMID?.lastName}
                        </>
                      ) : (
                        "-"
                      )}
                    </h2>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="flex-grow-1 ">
                      <div className="d-flex justify-content-center">
                        <div className="row">
                          <ul className="date-display d-flex align-items-center">
                            <button
                              className="btn btn-secondary py-0"
                              onClick={() =>
                                setSelectedDate(
                                  moment(selectedDate).subtract(1, "days")
                                )
                              }
                            >
                              <i
                                className="fa fa-angle-left"
                                onClick={() =>
                                  setSelectedDate((prev) =>
                                    prev.subtract(1, "days")
                                  )
                                }
                              ></i>
                            </button>
                            <h6 className="pl-3 pr-3 m-0" id="date-info">
                              {selectedDate.format("ddd, MMM DD, YYYY")}
                            </h6>
                            <button
                              className="btn  btn-secondary py-0"
                              onClick={() =>
                                setSelectedDate(
                                  moment(selectedDate).add(1, "days")
                                )
                              }
                            >
                              <i className="fa fa-angle-right"></i>
                            </button>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="col-md-3">
              <h2
                className=" mb-0 f-16 text-capitalize font-weight-bold"
                onClick={(e) => e.stopPropagation()}
              >
                <a data-toggle="tooltip" title="Re-assign Driver">
                  <i
                    className="fa fa-edit fa-w-16 text-success"
                    data-toggle="modal"
                    data-target="#myDriver"
                    onClick={openAssignDropDown}
                  ></i>
                </a>
                Driver Name:{" "}
                {current.driverId ? (
                  <>
                    {current.driverId.firstName} {current.driverId?.lastName}
                  </>
                ) : (
                  "-"
                )}
              </h2>
            </div> */}
                  <div className="col-md-3">
                    <div className="d-flex justify-content-center">
                      <h2 className=" mb-0 f-16 text-capitalize font-weight-bold">
                        {/* <svg className="svg-inline--fa fa-clock fa-w-16 text-success" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="clock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256,8C119,8,8,119,8,256S119,504,256,504,504,393,504,256,393,8,256,8Zm92.49,313h0l-20,25a16,16,0,0,1-22.49,2.5h0l-67-49.72a40,40,0,0,1-15-31.23V112a16,16,0,0,1,16-16h32a16,16,0,0,1,16,16V256l58,42.5A16,16,0,0,1,348.49,321Z"></path></svg> */}
                        <i className="fa fa-clock fa-w-16 text-success"></i>{" "}
                        Shift Time: {"MID"}
                      </h2>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="d-flex justify-content-center">
                      <h2 className=" mb-0 f-16 text-capitalize font-weight-bold">
                        {/* <svg className="svg-inline--fa fa-clock fa-w-16 text-success" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="clock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256,8C119,8,8,119,8,256S119,504,256,504,504,393,504,256,393,8,256,8Zm92.49,313h0l-20,25a16,16,0,0,1-22.49,2.5h0l-67-49.72a40,40,0,0,1-15-31.23V112a16,16,0,0,1,16-16h32a16,16,0,0,1,16,16V256l58,42.5A16,16,0,0,1,348.49,321Z"></path></svg> */}
                        <i className="fa fa-calendar-alt fa-w-16 text-success"></i>{" "}
                        Status:{" "}
                        {!routeDetails?.tripMID
                          ? "Scheduled"
                          : routeDetails?.tripMID?.isCompleted
                          ? "Completed"
                          : routeDetails?.tripMID?.tripStartTime
                          ? "In Progress"
                          : "Scheduled"}
                      </h2>
                    </div>
                  </div>
                  {/* <div>
              <h2 className=" mb-0 f-16 text-capitalize font-weight-bold">

                <i className="fa fa-clock fa-w-16 text-success"></i> Shift Time:
                {current.shiftTime}
              </h2>
            </div> */}
                  <div className="col-md-3">
                    <div className="d-flex justify-content-end">
                      {routeDetails?.tripMID?.isCompleted === true ? (
                        <button className="btn btn-success py-0">
                          Completed
                        </button>
                      ) : routeDetails?.tripMID?.tripStartTime ? (
                        <button
                          className="btn btn-warning py-0"
                          onClick={() => endTripHandler("toggleValuesMID")}
                        >
                          Ride End
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary py-0"
                          onClick={() => startTripHandler("toggleValuesMID")}
                        >
                          Start
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-body">
                <table
                  className="table border w-100 dataTable no-footer"
                  id="leads-table"
                  role="grid"
                  aria-describedby="leads-table_info"
                  style={{ overflowX: "auto" }}
                >
                  <thead className="card-header">
                    <tr role="row">
                      {/* <th
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowspan="1"
                    colspan="1"
                    aria-label="Mobile: activate to sort column ascending"
                  >
                    Route#
                  </th> */}
                      <th
                        title="Customer Name"
                        className="sorting_disabled border-right align-table-header"
                        rowspan="1"
                        colspan="1"
                        aria-label="Next Follow Up"
                        // onClick={startTripHandler}
                      >
                        Customer Name
                      </th>
                      <th
                        title="Type"
                        className="sorting border-right align-table-header"
                        tabIndex="0"
                        aria-controls="leads-table"
                        rowspan="1"
                        colspan="1"
                        aria-label="Lead Agent: activate to sort column ascending"
                      >
                        Type
                      </th>
                      <th
                        title="Pickup"
                        className="sorting border-right align-table-header"
                        tabIndex="0"
                        aria-controls="leads-table"
                        rowspan="1"
                        colspan="1"
                        aria-label="Lead Agent: activate to sort column ascending"
                      >
                        Pickup Time
                      </th>
                      <th
                        title="Pickup Drop Address"
                        className="sorting border-right w-30 extraWidth align-table-header"
                        tabIndex="0"
                        aria-controls="leads-table"
                        rowspan="1"
                        colspan="1"
                        aria-label="Lead Agent: activate to sort column ascending"
                      >
                        Pickup Drop Address
                      </th>
                      <th
                        title="Drop Off Time"
                        className="sorting border-right align-table-header"
                        tabIndex="0"
                        aria-controls="leads-table"
                        rowspan="1"
                        colspan="1"
                        aria-label="Lead Agent: activate to sort column ascending"
                      >
                        Drop Off Time
                      </th>
                      <th
                        title="Action"
                        className="text-right pr-20 sorting_disabled border-right align-table-header"
                        rowspan="1"
                        colspan="1"
                        aria-label="Action"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  {routeDetails && (
                    <tbody>
                      {" "}
                      {routeDetails?.route?.customerDetailsMID?.map((x, i) => {
                        if (current.id === routes.routeDetails?.route?.id) {
                          // if (
                          //   !x?.dateRange[x?.dateRange.length - 1]?.end ||
                          //   new Date(x?.dateRange[x?.dateRange.length - 1]?.end)
                          //     ?.toISOString()
                          //     .split("T")[0] +
                          //     "T00:00:00.000Z" >
                          //     new Date(selectedDate.format("YYYY-MM-DD"))
                          //       .toISOString()
                          //       .split("T")[0] +
                          //       "T00:00:00.000Z"
                          // )
                          if (
                            (!x?.dateRange[x?.dateRange.length - 1]?.end &&
                              x.dateRange.some(
                                ({ start }) =>
                                  new Date(start).toISOString().split("T")[0] +
                                    "T00:00:00.000Z" <=
                                  new Date(selectedDate.format("YYYY-MM-DD"))
                                    .toISOString()
                                    .split("T")[0] +
                                    "T00:00:00.000Z"
                              )) ||
                            (x?.dateRange[x?.dateRange.length - 1]?.end &&
                              x.dateRange.some(
                                ({ start, end }) =>
                                  new Date(start).toISOString().split("T")[0] +
                                    "T00:00:00.000Z" <=
                                    new Date(selectedDate.format("YYYY-MM-DD"))
                                      .toISOString()
                                      .split("T")[0] +
                                      "T00:00:00.000Z" &&
                                  new Date(end).toISOString().split("T")[0] +
                                    "T00:00:00.000Z" >
                                    new Date(selectedDate.format("YYYY-MM-DD"))
                                      .toISOString()
                                      .split("T")[0] +
                                      "T00:00:00.000Z"
                              ))
                          ) {
                            return (
                              <RoutesTableAccordionRow
                                route={current}
                                current={x}
                                i={i}
                                removeCustomerHandler={removeCustomerHandler}
                                pickCustomerHandler={pickCustomerHandler}
                                noshowCustomerHandler={noshowCustomerHandler}
                                dropCustomerHandler={dropCustomerHandler}
                                startTripHandler={startTripHandler}
                                endTripHandler={endTripHandler}
                                toggleValues={toggleValuesMID}
                                updateToggleValues={updateToggleValues}
                                toggleType="toggleValuesMID"
                              />
                            );
                          }
                        } else {
                          return <></>;
                        }
                      })}
                    </tbody>
                  )}
                </table>
              </div>
            </>
          )}

          {routeDetails?.route?.customerDetailsPM?.length > 0 && (
            <>
              <div className="p-2" style={{ background: "#e5e5e5" }}>
                <div className="d-flex justify-content-between">
                  <div className="col-md-3">
                    <h2
                      className=" mb-0 f-16 text-capitalize font-weight-bold"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <a data-toggle="tooltip" title="Re-assign Driver">
                        {/* <svg className="svg-inline--fa fa-edit fa-w-18 text-success" data-toggle="modal" data-target="#myDriver" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="edit" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><path fill="currentColor" d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"></path></svg> */}
                        <i
                          className="fa fa-edit fa-w-16 text-success"
                          data-toggle="modal"
                          data-target="#myDriver"
                          onClick={() => {
                            setShift("toggleValuesPM");
                            openAssignDropDown();
                          }}
                        ></i>
                      </a>
                      Driver Name:{" "}
                      {driverPM ? (
                        <>
                          {driverPM?.firstName} {driverPM?.lastName}
                        </>
                      ) : (
                        "-"
                      )}
                    </h2>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="flex-grow-1 ">
                      <div className="d-flex justify-content-center">
                        <div className="row">
                          <ul className="date-display d-flex align-items-center">
                            <button
                              className="btn btn-secondary py-0"
                              onClick={() =>
                                setSelectedDate(
                                  moment(selectedDate).subtract(1, "days")
                                )
                              }
                            >
                              <i
                                className="fa fa-angle-left"
                                onClick={() =>
                                  setSelectedDate((prev) =>
                                    prev.subtract(1, "days")
                                  )
                                }
                              ></i>
                            </button>
                            <h6 className="pl-3 pr-3 m-0" id="date-info">
                              {selectedDate.format("ddd, MMM DD, YYYY")}
                            </h6>
                            <button
                              className="btn  btn-secondary py-0"
                              onClick={() =>
                                setSelectedDate(
                                  moment(selectedDate).add(1, "days")
                                )
                              }
                            >
                              <i className="fa fa-angle-right"></i>
                            </button>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="col-md-3">
              <h2
                className=" mb-0 f-16 text-capitalize font-weight-bold"
                onClick={(e) => e.stopPropagation()}
              >
                <a data-toggle="tooltip" title="Re-assign Driver">
                  <i
                    className="fa fa-edit fa-w-16 text-success"
                    data-toggle="modal"
                    data-target="#myDriver"
                    onClick={openAssignDropDown}
                  ></i>
                </a>
                Driver Name:{" "}
                {current.driverId ? (
                  <>
                    {current.driverId.firstName} {current.driverId?.lastName}
                  </>
                ) : (
                  "-"
                )}
              </h2>
            </div> */}
                  <div className="col-md-3">
                    <div className="d-flex justify-content-center">
                      <h2 className=" mb-0 f-16 text-capitalize font-weight-bold">
                        {/* <svg className="svg-inline--fa fa-clock fa-w-16 text-success" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="clock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256,8C119,8,8,119,8,256S119,504,256,504,504,393,504,256,393,8,256,8Zm92.49,313h0l-20,25a16,16,0,0,1-22.49,2.5h0l-67-49.72a40,40,0,0,1-15-31.23V112a16,16,0,0,1,16-16h32a16,16,0,0,1,16,16V256l58,42.5A16,16,0,0,1,348.49,321Z"></path></svg> */}
                        <i className="fa fa-clock fa-w-16 text-success"></i>{" "}
                        Shift Time: {"PM"}
                      </h2>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="d-flex justify-content-center">
                      <h2 className=" mb-0 f-16 text-capitalize font-weight-bold">
                        {/* <svg className="svg-inline--fa fa-clock fa-w-16 text-success" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="clock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256,8C119,8,8,119,8,256S119,504,256,504,504,393,504,256,393,8,256,8Zm92.49,313h0l-20,25a16,16,0,0,1-22.49,2.5h0l-67-49.72a40,40,0,0,1-15-31.23V112a16,16,0,0,1,16-16h32a16,16,0,0,1,16,16V256l58,42.5A16,16,0,0,1,348.49,321Z"></path></svg> */}
                        <i className="fa fa-calendar-alt fa-w-16 text-success"></i>{" "}
                        Status:{" "}
                        {!routeDetails?.tripPM
                          ? "Scheduled"
                          : routeDetails?.tripPM?.isCompleted
                          ? "Completed"
                          : routeDetails?.tripPM?.tripStartTime
                          ? "In Progress"
                          : "Scheduled"}
                      </h2>
                    </div>
                  </div>
                  {/* <div>
              <h2 className=" mb-0 f-16 text-capitalize font-weight-bold">

                <i className="fa fa-clock fa-w-16 text-success"></i> Shift Time:
                {current.shiftTime}
              </h2>
            </div> */}
                  <div className="col-md-3">
                    <div className="d-flex justify-content-end">
                      {routeDetails?.tripPM?.isCompleted === true ? (
                        <button className="btn btn-success py-0">
                          Completed
                        </button>
                      ) : routeDetails?.tripPM?.tripStartTime ? (
                        <button
                          className="btn btn-warning py-0"
                          onClick={() => endTripHandler("toggleValuesPM")}
                        >
                          Ride End
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary py-0"
                          onClick={() => startTripHandler("toggleValuesPM")}
                        >
                          Start
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-body">
                <table
                  className="table border w-100 dataTable no-footer"
                  id="leads-table"
                  role="grid"
                  aria-describedby="leads-table_info"
                  style={{ overflowX: "auto" }}
                >
                  <thead className="card-header">
                    <tr role="row">
                      {/* <th
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowspan="1"
                    colspan="1"
                    aria-label="Mobile: activate to sort column ascending"
                  >
                    Route#
                  </th> */}
                      <th
                        title="Customer Name"
                        className="sorting_disabled border-right align-table-header"
                        rowspan="1"
                        colspan="1"
                        aria-label="Next Follow Up"
                        // onClick={startTripHandler}
                      >
                        Customer Name
                      </th>
                      <th
                        title="Type"
                        className="sorting border-right align-table-header"
                        tabIndex="0"
                        aria-controls="leads-table"
                        rowspan="1"
                        colspan="1"
                        aria-label="Lead Agent: activate to sort column ascending"
                      >
                        Type
                      </th>
                      <th
                        title="Pickup"
                        className="sorting border-right align-table-header"
                        tabIndex="0"
                        aria-controls="leads-table"
                        rowspan="1"
                        colspan="1"
                        aria-label="Lead Agent: activate to sort column ascending"
                      >
                        Pickup Time
                      </th>
                      <th
                        title="Pickup Drop Address"
                        className="sorting border-right w-30 extraWidth align-table-header"
                        tabIndex="0"
                        aria-controls="leads-table"
                        rowspan="1"
                        colspan="1"
                        aria-label="Lead Agent: activate to sort column ascending"
                      >
                        Pickup Drop Address
                      </th>
                      <th
                        title="Drop Off Time"
                        className="sorting border-right align-table-header align-table-header"
                        tabIndex="0"
                        aria-controls="leads-table"
                        rowspan="1"
                        colspan="1"
                        aria-label="Lead Agent: activate to sort column ascending"
                      >
                        Drop Off Time
                      </th>
                      <th
                        title="Action"
                        className="text-right pr-20 sorting_disabled border-right align-table-header"
                        rowspan="1"
                        colspan="1"
                        aria-label="Action"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  {routeDetails && (
                    <tbody>
                      {" "}
                      {routeDetails?.route?.customerDetailsPM?.map((x, i) => {
                        if (current.id === routes.routeDetails?.route?.id) {
                          // if (
                          //   !x?.dateRange[x?.dateRange.length - 1]?.end ||
                          //   new Date(x?.dateRange[x?.dateRange.length - 1]?.end)
                          //     ?.toISOString()
                          //     .split("T")[0] +
                          //     "T00:00:00.000Z" >
                          //     new Date(selectedDate.format("YYYY-MM-DD"))
                          //       .toISOString()
                          //       .split("T")[0] +
                          //       "T00:00:00.000Z"
                          // )
                          if (
                            (!x?.dateRange[x?.dateRange.length - 1]?.end &&
                              x.dateRange.some(
                                ({ start }) =>
                                  new Date(start).toISOString().split("T")[0] +
                                    "T00:00:00.000Z" <=
                                  new Date(selectedDate.format("YYYY-MM-DD"))
                                    .toISOString()
                                    .split("T")[0] +
                                    "T00:00:00.000Z"
                              )) ||
                            (x?.dateRange[x?.dateRange.length - 1]?.end &&
                              x.dateRange.some(
                                ({ start, end }) =>
                                  new Date(start).toISOString().split("T")[0] +
                                    "T00:00:00.000Z" <=
                                    new Date(selectedDate.format("YYYY-MM-DD"))
                                      .toISOString()
                                      .split("T")[0] +
                                      "T00:00:00.000Z" &&
                                  new Date(end).toISOString().split("T")[0] +
                                    "T00:00:00.000Z" >
                                    new Date(selectedDate.format("YYYY-MM-DD"))
                                      .toISOString()
                                      .split("T")[0] +
                                      "T00:00:00.000Z"
                              ))
                          ) {
                            return (
                              <RoutesTableAccordionRow
                                route={current}
                                current={x}
                                i={i}
                                removeCustomerHandler={removeCustomerHandler}
                                pickCustomerHandler={pickCustomerHandler}
                                noshowCustomerHandler={noshowCustomerHandler}
                                dropCustomerHandler={dropCustomerHandler}
                                startTripHandler={startTripHandler}
                                endTripHandler={endTripHandler}
                                toggleValues={toggleValuesPM}
                                updateToggleValues={updateToggleValues}
                                toggleType="toggleValuesPM"
                              />
                            );
                          }
                        } else {
                          return <></>;
                        }
                      })}
                    </tbody>
                  )}
                </table>
              </div>
            </>
          )}
        </Accordion.Body>
        {/* </div> */}
      </Accordion.Item>

      {/* <tr
        role="row"
        className="odd border"
        key={i}
        onMouseLeave={() => setshowMoreVertDropDown(false)}
      >
        <td className="border-right">
          <b>
            {current?.routeNumber} {current?.routeNumberReturn && "( Return )"}
          </b>
        </td>
        <td className="border-right">{current?.customerDetails?.length}</td>
    
        <td className="border-right">
          <ul>
            {distinctArray?.map((x, i) => (
              <li>
                {" "}
                {x.type.name}
                {i !== distinctArray.length - 1 ? "," : ""}
              </li>
            ))}
          </ul>
        </td>
        <td className="border-right">
          {distinctDaysArray.map((x, i) => (
            <span>
              {" "}
              {x}
              {i !== distinctDaysArray.length - 1 ? "," : ""}
            </span>
          ))}
        </td>
        <td className="border-right" style={{ textAlign: "center" }}>
          <button
            className="btn btn-sm btn-primary"
            // onClick={() => navigate(`/routes/details/${current.id}`)}
            onClick={() => navigate(`/ride-action/${current.id}`)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-eye"
              viewBox="0 0 16 16"
            >
              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
            </svg>{" "}
          </button>
        </td>
        <td className="border-right">
          {current.driverId ? (
            <>
              {current.driverId.firstName} {current.driverId?.lastName}
            </>
          ) : (
            "-"
          )}
        </td>
        <td className="border-right">
          {current.status === "In Progress" ? "Yes" : "No"}
        </td>

        <td className="border-right">
          <button
            className="btn btn-success"
            onClick={
              current.driverBookingStatus !== "Unassigned"
                ? unassignedRoute
                : openAssignDropDown
            }
          >
            {current.driverBookingStatus !== "Unassigned"
              ? "Unassigned Driver"
              : "Assign Driver"}
          </button>
        </td>
      
        <td className=" text-right pr-20">
          <div className="task_view">
            <div className="dropdown">
              <Link
                to=""
                className="task_view_more d-flex align-items-center justify-content-center dropdown-toggle"
                type="link"
                data-toggle="dropdown"
                onClick={openMoreVertDropDown}
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="icon-options-vertical icons"></i>
              </Link>
              <div
                className={`dropdown-menu dropdown-menu-right ${
                  showMoreVertDropDown
                    ? "dropdown-menu dropdown-menu-right show"
                    : "dropdown-menu dropdown-menu-right"
                }`}
                aria-labelledby="dropdownMenuLink-20"
              >
                {auth?.user?.permissionId?.routes?.create === true &&
                  !current.routeNumberReturn &&
                  !current.isReturnRoute && (
                    <Link
                      className="dropdown-item"
                      to={`create-return/${current.id}`}
                      onClick={() => createReturnRouteHandler(current)}
                    >
                      <i className="fa fa-plus"> Create return route</i>
                    </Link>
                  )}
                {auth?.user?.permissionId?.routes?.update === true && (
                  <Link to={`edit/${current.id}`} className="dropdown-item">
                    <i className="fa fa-edit"> Edit</i>
                  </Link>
                )}

   
                <Link
                  className="dropdown-item"
                  to={`/routes/details/${current.id}`}
                  // onClick={() => navigate(`/routes/details/${current.id}`)}
                >
                  <i className="fa fa-address-card-o"> Full Details</i>
                </Link>

                {auth?.user?.permissionId?.routes?.update === true && (
                  <Link
                    className="dropdown-item"
                    to=""
                    onClick={() => editOrderHandler(current)}
                  >
                    <i className="fa fa-edit"> Edit Ride Pattern</i>
                  </Link>
                )}
                <Link
                  className="dropdown-item"
                  to={`/route-log/${current.id}`}
                  // onClick={() => navigate(`/route-log/${current.id}`)}
                >
                  <i className="fa fa-address-card-o"> View Route Log</i>
                </Link>

                {auth?.user?.permissionId?.routes?.delete === true && (
                  <Link
                    className="dropdown-item delete-table-row"
                    to=""
                    data-id="20"
                    onClick={() => deleteRouteHandler(current)}
                  >
                    <i className="fa fa-trash"> Delete</i>
                  </Link>
                )}

             
              </div>
            </div>
          </div>
        </td>
      </tr> */}
      <AssignDriverModal
        showDropDown={assignDropDown}
        openDropDown={openAssignDropDown}
        current={current}
        shift={shift}
        selectedDate={selectedDate}
        index={i}
      />
      <AdminRouteDeleteModal
        showDropDown={showDropDown}
        openDropDown={openDropDown}
        current={current}
        index={i}
      />
      {/* <AdminDispatchAssignModalComponent
      showDropDown={showAssignDriverDropDown}
      openDropDown={openAssignDriverDropDown}
      current={current}
      index={i}
      assigned={"assigned"}
    /> */}
      {showDeleteModal && (
        <AdminRouteDeleteModal
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          selectedRoute={selectedRoute}
          setSelectedRoute={setSelectedRoute}
        />
      )}
      {showStopModal && (
        <AdminRouteDisableModal
          showModal={showStopModal}
          setShowModal={setShowStopModal}
          selectedRoute={selectedRoute}
          setSelectedRoute={setSelectedRoute}
        />
      )}
      {showReactivateModal && (
        <AdminRouteReactivateModal
          showModal={showReactivateModal}
          setShowModal={setShowReactivateModal}
          selectedRoute={selectedRoute}
          setSelectedRoute={setSelectedRoute}
        />
      )}
    </>
  );
};
export default RoutesTableAccordion;
